import { DataTable } from '../../components/DataTable'
import { QuoteItemsClient } from '../../globals/Clients/QuoteItemsClient'
import { QuoteItemResource } from '../../globals/Resources/QuoteItemResource'
import $ from 'jquery'
import { Components } from '../../components/_base'
import { QuoteItemArtworksClient } from '../../globals/Clients/QuoteItemArtworkClient'
import { ItemPriceCalculator } from '../../globals/Utilities/ItemPriceCalculator'
import { QuoteHeaderResource } from '../../globals/Resources/QuoteHeaderResource'
import { FileUploadClient } from '../../globals/Clients/FileUploadClient'
import { NotesClient } from '../../globals/Clients/NotesClient'

class EmSheetDatatable extends DataTable {

  static _name = 'artwork-datatable'
  static _selector = '.composition.artwork-datatable'

  constructor(element) {
    super(element)

    this.quoteHeaderId = $(element).data('quote-header-id')
    this.itemsClient = new QuoteItemsClient(this.quoteHeaderId)
    this.artworkClient = new QuoteItemArtworksClient()
    this.fileClient = new FileUploadClient()
    this.noteClient = new NotesClient(this.quoteHeaderId)
  }

  boot() {
    this.bindEvents()
    this.setArtworkFormatter()
    this.setEpsFormatter()
    super.boot()
  }

  bindEvents() {
    this.registerCallback('ajaxResponse', (url, params, originalResponse, previousValue) => {
      return previousValue
    })

    $('#update-eps-file').on('update-eps-file', (e, data) => {
      e.preventDefault()
      this.updateEpsFile(data)
    })
  }

  updateEpsFile(data) {
    let selectedRows = this.table.getSelectedRows()
    let artworkToUpdate = []

    selectedRows.forEach(element =>
      {
        let artworkIds = element._row.data.artworkIds
        let artworks = ''

        if(artworkIds.toString().includes(', ')) {
          artworks = artworkIds.split(', ')
        }
        else {
          artworks = [artworkIds.toString()]
        }

        artworks.forEach(element => {
          artworkToUpdate.push(element)
        })
      }
    )

    let form = new FormData();
    form.append("file", $(data.target.element).find("input[name='eps_file_id']")[0].files[0]);

    let fileId = ''
    let title = ''
    let location = ''

    this.fileClient.store(form)
      .then(response => {

        fileId = response.data.id
        title = response.data.title
        location = response.data.location

        artworkToUpdate.forEach(element => {
          this.artworkClient.update(element, {'eps_file_id': fileId})
        })

        selectedRows.forEach(element => {
          let lineId = element.getData().id

          let html = "<a href='" + location + "' download>" + title + "</a>"

          let data = {
            'artwork': {
              'eps_file_id': html,
              'eps_file_location': location,
              'eps_file_name': title,
              'colors': element.getData().artwork.colors,
              'height': element.getData().artwork.height,
              'width': element.getData().artwork.width,
              'implementation': element.getData().artwork.implementation,
              'product_location': element.getData().artwork.product_location,
              'size': element.getData().artwork.size,
              'preview_image_location': element.getData().artwork.preview_image_location,
              'file_upload_id': element.getData().artwork.file_upload_id
            }
          }

          this.table.updateRow(lineId, data)
          this.table.deselectRow(lineId)
          this.table.redraw(true)
        })
      })
  }

  setArtworkFormatter() {
    let column = {'field': 'artwork.file_upload_id'}
    this.registerFormatter(column, this.artworkFormatter.bind(this), 0)
  }

  artworkFormatter(cell, formatterParams, onRendered, previousValue) {
    let rowData = cell.getRow().getData() || {artwork: []}
    return "<img style='height: 100px; width: auto;' src='" + rowData.artwork.preview_image_location + "'>"
  }

  setEpsFormatter() {
    let column = {'field': 'artwork.eps_file_id'}
    this.registerFormatter(column, this.epsFormatter.bind(this), 0)
  }

  epsFormatter(cell, formatterParams, onRendered, previousValue) {
    let rowData = cell.getRow().getData() || {artwork: []}

    if(rowData.artwork.eps_file_location === null || rowData.artwork.eps_file_location === '' || rowData.artwork.eps_file_location === undefined) {
      return '<p>No EPS File</p>'
    }
    return "<a href='" + rowData.artwork.eps_file_location + "' download>" + rowData.artwork.eps_file_name + "</a>"
  }

}

export { EmSheetDatatable }