import { DataTable } from '../../components/DataTable'
import { QuoteItemsClient } from '../../globals/Clients/QuoteItemsClient'
import { QuoteItemResource } from '../../globals/Resources/QuoteItemResource'
import $ from 'jquery'
import { Components } from '../../components/_base'
import { QuoteItemArtworksClient } from '../../globals/Clients/QuoteItemArtworkClient'
import { ItemPriceCalculator } from '../../globals/Utilities/ItemPriceCalculator'
import { QuoteHeaderResource } from '../../globals/Resources/QuoteHeaderResource'
import { FileUploadClient } from '../../globals/Clients/FileUploadClient'
import { NotesClient } from '../../globals/Clients/NotesClient'

class LineItemDatatable extends DataTable {

  static _name = 'line-item-datatable'
  static _selector = '.composition.line-item-datatable'

  static getArtworkRow(artwork) {
    let filename = artwork.filename || ''
    let width = artwork.width || ''
    let height = artwork.height || ''
    let eps_file = artwork.eps_file ? artwork.eps_file.title : ''
    let eps_link = artwork.eps_file ? artwork.eps_file.location : ''


    let image = `
        <div class="artwork-descriptor" data-artwork-id="${artwork.id}">
          <div class="actions-wrapper">
            <i class="fa fa-trash-alt" style="color: #f45f4f;"></i>
          </div>
          <div class="image-wrapper">
            <img src="${artwork.file_upload.thumbnail}">
          </div>
          <form class="artwork-form" target="/api/quotes/artwork/">
            <input type="hidden" value="${artwork.id}" name="id">
            <ul>
              <li>Colors: <input type="text" name="colors" value="${artwork.colors}"/>
              <li>Implementation: <input type="text" name="implementation" value="${artwork.implementation}"/></li>
              <li>Location: 
                <input type="text" list="location-list-${artwork.id}" name="product_location" value="${artwork.product_location}"/>
                <datalist id="location-list-${artwork.id}">
                    <option data-value="chest_left" value="Chest - Left"></option>
                    <option data-value="chest_right" value="Chest - Right"></option>
                    <option data-value="full_front" value="Full - Front"></option>
                    <option data-value="full_back" value="Full - Back"></option>
                    <option data-value="sleeve_left" value="Sleeve - Left"></option>
                    <option data-value="sleeve_right" value="Sleeve - Right"></option>
                    <option data-value="neck" value="Nape (Neck)"></option>
                    <option data-value="cuff_left" value="Cuff - Left"></option>
                    <option data-value="cuff_right" value="Cuff - Right"></option>
                    <option data-value="hat" value="Hat"></option>
                  </datalist>
              </li>
              <li>Size: <select name="size">
                            <option ${ artwork.size === 'standard' ? 'selected="selected"' : '' } value="standard">Standard</option>
                            <option ${ artwork.size === 'big' ? 'selected="selected"' : '' } value="big">Big As Possible</option>
                            <option ${ artwork.size === 'small' ? 'selected="selected"' : '' } value="small">Small As Possible</option>
                            <option ${ artwork.size === 'custom' ? 'selected="selected"' : '' } value="custom">Custom</option>
                        </select>
              </li>
              <li>Dimensions: <input type="number" name="width" step="0.1" value="${ width }"> inches
                              <input type="number" name="height" step="0.1" value="${ height }"> inches
              </li>`

      if(eps_link !== '') {
        image = image + `<li>EPS File: <a href="${eps_link}" download>Download ${eps_file}</a></li>`
      }

      image = image + `<li>Change EPS File: <input type="file" name="eps_file"></li>
            </ul>
            <div>
            <br>
            
              <div class="component c-button">          
                <button data-method="event" data-action="update-artwork" data-target="$(this).closest('form')">                
                  Save
                  <i class="fas fa-spinner fa-spin"></i>
                </button>
              </div>
              
            </div>
          </form>
          
          <form class="image-form" target="/api/files">
            <input type="file" id="artwork-image-${artwork.id}" name="artwork-image" accept="image/png, image/jpeg, image/tiff">
            
            <div> 
            <br>
            
                <div class="component c-button">
                  <button data-method="event" data-action="edit-image" data-target="$(this).closest('form')">                
                    Change Image
                    <i class="fas fa-spinner fa-spin"></i>
                  </button>            
                </div>
            </div>
          </form>
          
        </div>
      `

    return image
  }

  static getNoteRow() {
    return '<textarea></textarea>'
  }

  static getViewArtworkClone(data, template) {
    let copy = $('<div></div>')
    copy.append($(template).html())

    data.forEach(artwork => {
      let image = $(this.getArtworkRow(artwork))
      copy.find('.item-artwork-wrapper').append(image)
    })

    return copy
  }

  static getNoteClone(data, template) {
    let copy = $('<div></div>')
    copy.append($(template).html())

    /*data.forEach(comment => {*/
     /* let com = $(this.getNoteRow())*/
      copy.find('.comment-modal').append(data)
    /*})*/

    /*console.log('copy', copy)*/
    return copy
  }

  static unitPriceFormatter(cell, formatterParams, onRendered) {
    let rowData = cell.getRow().getData()
    let sale_prices = LineItemDatatable.getSalePrices(rowData)
    let selected_commission = rowData.commission_percent

    return LineItemDatatable.getSellPriceFromData(sale_prices, 'unit', selected_commission)
  }

  static sellPerItemFormatter(cell, formatterParams, onRendered) {
    let rowData = cell.getRow().getData()
    let sale_prices = LineItemDatatable.getSalePrices(rowData)
    let selected_commission = rowData.commission_percent

    return LineItemDatatable.getSellPriceFromData(sale_prices, 'each', selected_commission)
  }

  static sellTotalFormatter(cell, formatterParams, onRendered) {
    let rowData = cell.getRow().getData()
    let sale_prices = LineItemDatatable.getSalePrices(rowData)
    let selected_commission = rowData.commission_percent

    return LineItemDatatable.getSellPriceFromData(sale_prices, 'total', selected_commission)
  }

  static getSalePrices(data) {
    let quantity = data.quantity
    let unit_cost = data.unit_material_charge
    let run_charge_gross = data.run_charge_gross
    let commission = data.commission_percent
    let margin = 0
    switch(commission) {
      case 13.5:
        margin = 100 - 26.5 - commission
        break;
      case 10:
        margin = 100 - 27 - commission
        break;
      case 7:
        margin = 100 - 27 - commission
        break;
      case 5:
        margin = 100 - 25 - commission
        break;
      default:
        margin = 100 - 26.5 - commission
    }

    let output = {}

    let comm = new ItemPriceCalculator(commission, margin, quantity, unit_cost, run_charge_gross)

    output[commission] = {
      unit: comm.price_per_unit(),
      each: comm.sale_price_per_unit(),
      total: comm.sale_price_total()
    }

    return output
  }

  static getSellPriceFromData(prices, key, commission) {
    if(commission < 1) {
      commission = commission * 100
    }
    try {
      let str = `$${Number(prices[commission][key]).toFixed(2)}`
      return str
    } catch {
      return 'Select commission'
    }
  }



  constructor(element) {
    super(element)

    this.quoteHeaderId = $(element).data('quote-header-id')
    this.modalTemplate = $(this.element).find('.item-artwork-modal').first()
    this.noteTemplate = $(this.element).find('.comment-modal').first()
    this.itemsClient = new QuoteItemsClient(this.quoteHeaderId)
    this.artworkClient = new QuoteItemArtworksClient()
    this.fileClient = new FileUploadClient()
    this.noteClient = new NotesClient(this.quoteHeaderId)
  }

  boot() {
    this.bindEvents()
    this.setArtworkFormatter()
    this.setNoteFormatter()
    this.setSelectFormatter()
    this.setUnitPriceFormatter()
    this.setSellPerItemFormatter()
    this.setSellTotalFormatter()
    super.boot()
  }

  bindEvents() {
    $(this.element).on('delete-row', (e, data) => {
      this.deleteItem(data)
    })

    $(this.element).on('edited-row', (e, data) => {
      this.updateItem(data)
    })

    $('#update-multiple-artwork .logo-fieldset-again').find('.component').each((i, el) => {
      if($(el).hasClass('c-button')) {
        return true
      }
      if($(el).hasClass('c-media-selector')) {
        return true
      }
      Components.forceBoot(el)
    })

    $('#update-multiple-artwork').on('update-multiple-artwork', (e, data) => {
      e.preventDefault()
      this.updateMultipleArtwork(data)
    })

    $('#update-multiple-lines').on('update-multiple-lines', (e, data) => {
      e.preventDefault()
      this.updateMultipleLines(data)
    })

    $('.select-all-items-fieldset').on('select-all-items', (e, data) => {
      e.preventDefault()
      this.selectAllLines()
    })

    $('.select-all-items-fieldset').on('deselect-all-items', (e, data) => {
      e.preventDefault()
      this.deselectAllLines()
    })

    this.registerCallback('ajaxResponse', (url, params, originalResponse, previousValue) => {
      return previousValue
    })
  }

  deleteItem(data) {
    this.itemsClient.destroy(data.id)
      .catch((data) => {
        this.table.addRow(data)
      })
  }

  updateItem(data) {
    let values = data.cell.getData()
    let model = QuoteItemResource.make(values)
    this.itemsClient.update(model.id, model)
      .catch(() => {
        data.row.update(values)
      })
  }

  updateMultipleArtwork(data) {

    let selectedRows = this.table.getSelectedRows()
    let selectedItemIds = []
    let artworkToDelete = []

    selectedRows.forEach(element =>
      {
        let id = element._row.data.id
        selectedItemIds.push(id)

        let artwork = $(element._row.element).find('.artwork-descriptor')

        artwork.each(function() {
          artworkToDelete.push($(this).data('artwork-id'))
        })
      }
    )

    let fileUploadId = $(data.target.element).find("input[name='file_upload_id']").val()
    let productLocation = $(data.target.element).find("input[name='product_location']").val()
    let colorsVal = $(data.target.element).find("input[name='colors']").val()
    let logoSize = $(data.target.element).find("select[name='size']").val()

    let form = new FormData();
    form.append("file", $(data.target.element).find("input[name='eps_file_id']")[0].files[0]);
    console.log('form', form)
    let eps_file_id = null

    let logoWidth, logoHeight = 0
    if(logoSize === 'custom') {
      logoWidth = $(data.target.element).find("input[name='width']").val()
      logoHeight = $(data.target.element).find("input[name='height']").val()
    }

    let fileName = $(data.target.element).find("input[name='filename']").val()
    let implementationVal = $(data.target.element).find("select[name='implementation']").val()

    let action = $(data.target.element).find("select[name='action']").val()

    if(action === 'overwrite') {
      artworkToDelete.forEach(element => {
        this.artworkClient.destroy(element)
      })
    }

    if(action === '') {
      return
    }

    let t = this

    this.fileClient.store(form)
      .then(function(response) {
        eps_file_id = response.id

        for(let i = 0; i < selectedItemIds.length; i++) {
          if (logoSize !== 'custom') {
            var thisPayload = {
              'file_upload_id': fileUploadId,
              'product_location': productLocation,
              'colors': colorsVal,
              'size': logoSize,
              'filename': fileName,
              'implementation': implementationVal,
              'quote_item_id': selectedItemIds[i],
              'eps_file_id': eps_file_id
            }
          }
          else {
            var thisPayload = {
              'file_upload_id': fileUploadId,
              'product_location': productLocation,
              'colors': colorsVal,
              'size': logoSize,
              'filename': fileName,
              'implementation': implementationVal,
              'quote_item_id': selectedItemIds[i],
              'width': logoWidth,
              'height': logoHeight,
              'eps_file_id': eps_file_id
            }
          }

          t.artworkClient.store(thisPayload)
        }
    })

    location.reload()
  }

  updateMultipleLines(data) {
    let selectedRows = this.table.getSelectedRows()
    let selectedItemIds = []

    selectedRows.forEach(element =>
      {
        let id = element._row.data.id
        selectedItemIds.push(id)
      }
    )

    let formData = $(data.target.element).find(":input[value!='']").serializeJSON()

    for (var key in formData) {
      if (formData.hasOwnProperty(key)) {
        //Now, object[key] is the current value
        if (formData[key] === null || formData[key] === '')
          delete formData[key];
      }
    }

    for(let i = 0; i < selectedItemIds.length; i++) {
      var payload = formData
      payload.id = selectedItemIds[i]

      this.itemsClient.update(selectedItemIds[i], formData)

      let row = this.table.getRow(payload.id)
      row.update(payload)

      this._loadData()
    }

  }

  selectAllLines() {
    let tableData = this.table.getData()
    tableData.forEach((row, index) => {
      console.log(row, index)
      this.table.selectRow(row.id)
    })
  }

  deselectAllLines() {
    let tableData = this.table.getData()
    tableData.forEach((row, index) => {
      this.table.deselectRow(row.id)
    })
  }

  setArtworkFormatter() {
    let column = {'field': 'artwork'}
    this.registerFormatter(column, this.artworkFormatter.bind(this), 0)
    this.registerCallback('cellClick', function(e, cell) {
      if(cell._cell.column.field !== 'select_row') {
        e.stopPropagation();
      }
    }, 10)
  }

  setNoteFormatter() {
    let column = {'field': 'comments'}
    this.registerFormatter(column, this.noteFormatter.bind(this), 1)
  }

  setSelectFormatter() {
    let column = {'field': 'select_row'}
    this.registerFormatter(column, this.selectFormatter.bind(this), 2)
  }

  artworkFormatter(cell, formatterParams, onRendered, previousValue) {
    let rowData = cell.getRow().getData() || {artwork: []}

    onRendered(() => {
      let clone = $(cell.getElement())


      clone.find('.actions-wrapper .fa-trash-alt').on('click', (e) => {
        let el = $(e.currentTarget)
        let description = el.closest('.artwork-descriptor')
        let id = description.data('artwork-id')

        this.artworkClient.destroy(id)
          .then(quoteItemArtworkResource => {
            description.fadeOut(250, () => {
              description.remove()
            })
          })
      })

      clone.find('[name="quote_item_id"]').val(rowData.id)

      clone.find('.component').each((i, el) => {
        Components.forceBoot(el)
      })

      clone.find('.c-datatable').data('datatable').registerCallback('rowSelected', (row) => {
        $(clone).find('.logo-fieldset-again [name="file_upload_id"]').val(row.getData().id)
        $(clone).find('.logo-fieldset-again .c-modal').data('modal').close()
      }, 11)



      clone.on('submit-artwork', '.logo-fieldset-again', (e, data) => {
        let serialized = $(e.target).serializeJSON()
        let buttonInst = $(data.button.element).data('button')
        serialized.quote_item_id = rowData.id

        let saveArtwork = this.artworkClient.store(serialized)

        buttonInst
          .loading(saveArtwork)
          .then(quoteItemArtworkResource => {
            let row = LineItemDatatable.getArtworkRow(quoteItemArtworkResource.toArray().data)
            let newData = cell.getRow().getData()

            clone.find('.item-artwork-wrapper').append(row)

            newData.artwork.push(quoteItemArtworkResource.toArray())
            cell.getTable().updateData([newData])
          })
      })

      clone.find('.artwork-form').on('update-artwork', (e, data) => {
        let t = this
        let s = data.target.element.serializeJSON()
        let fileInput = data.target.element.find('input[type="file"]')[0].files[0]

        if(fileInput) {
          let formData = new FormData()
          formData.append('file', fileInput)

          this.fileClient.store(formData)
            .then(function(response) {
              s.eps_file_id = response.id
              $(data.target.element.find('a')[0]).attr('href', response.location)
              $(data.target.element.find('a')[0]).text('Download ' + response.title)
            })
            .then(function() {
              t.artworkClient.update(s.id, s)
            })
        }

        else {
          this.artworkClient.update(s.id, s)
        }

      })

      clone.find('.image-form').on('edit-image', (e, data) => {
        let t = this
        let form = data.target.element
        let fileInput = form.find('input[type="file"]')[0]
        let s = fileInput.files[0]
        let image = form.siblings('.image-wrapper').find('img')
        let artworkForm = form.siblings('.artwork-form').serializeJSON()

        let formData = new FormData()
        formData.append('file', s)

        this.fileClient.store(formData)
          .then(function(response) {
            let file = response
            t.artworkClient.update(artworkForm.id, { 'file_upload_id' : response.id })
              .then(function(response) {
                image.attr('src', file.thumbnail)
                form[0].reset()
              })
        })
      })

    })

    rowData.artwork = rowData.artwork || []

    let html = LineItemDatatable.getViewArtworkClone(rowData.artwork, this.modalTemplate).html()

    return html
  }

  noteFormatter(cell, formatterParams, onRendered, previousValue) {
    let rowData = cell.getRow().getData()

    onRendered(() => {

      let clone = $(cell.getElement())

      $('.add-item-note', clone).on('click', function(e) {
        e.stopPropagation()
      });

      let datatable = clone.find('.item-notes-datatable')
      let url = '/api/quotes/headers/' + this.quoteHeaderId + '/notes?category=po_comment&entity_id=' + rowData.id + '&entity_type=quote_item'

      datatable.data('url', url)

      clone.find('.component').each((i, el) => {
        Components.forceBoot(el)
      })

      clone.on('add-item-note', (e, data) => {
        let buttonInst = $(data.button.element).data('button')
        let note = { 'note': $(data.target.element).val() }
        let itemId = rowData.id
        let userId = $('body').data('user-id')


        let saveNote = this.noteClient.store(note, userId, itemId, 'quote_item')

        buttonInst
          .loading(saveNote)
          .then(noteResource => {
            datatable.data('datatable').addData(noteResource)
          })
      })
    })

    let html = LineItemDatatable.getNoteClone(rowData.comments, this.noteTemplate).html()

    return html
  }

  selectFormatter(cell, formatterParams, onRendered, previousValue) {
    return '<p style="text-align: center;">Select <i class="fas fa-check"></i></p>';
  }

  setUnitPriceFormatter() {
    let column = {title: 'Unit Sell Price'}
    this.registerFormatter(column, LineItemDatatable.unitPriceFormatter, 0)
  }

  setSellPerItemFormatter() {
    let column = {title: 'Sell Each'}
    this.registerFormatter(column, LineItemDatatable.sellPerItemFormatter, 0)
  }

  setSellTotalFormatter() {
    let column = {title: 'Total Sale'}
    this.registerFormatter(column, LineItemDatatable.sellTotalFormatter, 0)
  }
}

export { LineItemDatatable }