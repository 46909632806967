import 'jquery-serializejson'
import { AbstractClient } from './AbstractClient'
import { QuoteChargesCollectionResource } from '../Resources/QuoteChargesCollectionResource'

/**
 * @method get(int id)
 * @method index()
 * @method store(QuoteHeaderResource data)
 * @method update(int id, QuoteHeaderResource data)
 * @method delete(int id)
 */
class QuoteHeadersQuoteChargesClient extends AbstractClient {

  collectionType = QuoteChargesCollectionResource

  get uri() {
    return '/api/quotes/headers/' + this.header_id + '/charges'
  }

  constructor(header_id) {
    super()
    this.header_id = header_id
  }

  update(id, data) {
    data = this.constructor.parseData(data)

    if(data instanceof FormData) {
      data.append('chargeable_id', this.header_id)
      data.append('chargeable_type', 'quote_header')
    } else {
      data.data.chargeable_id = this.header_id
      data.data.chargeable_type = 'quote_header'
    }

    return super.update(id, data)
  }

  store(data) {
    data = this.constructor.parseData(data)


    if(data instanceof FormData) {
      data.append('chargeable_id', this.header_id)
      data.append('chargeable_type', 'quote_header')
    } else {
      data.data.chargeable_id = this.header_id
      data.data.chargeable_type = 'quote_header'
    }

    return super.store(data)
  }
}

export { QuoteHeadersQuoteChargesClient }