import { AbstractResource } from './AbstractResource'

class QuoteStateResource extends AbstractResource {

  static keys = {
    "id": Number,
    "name": String,
    "description": String,
    "title": String
  }
}

export { QuoteStateResource }