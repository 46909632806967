import 'jquery-serializejson'
import axios from 'axios'
import { TypeValidator } from '../Utilities/TypeValidator'
import { AbstractResource } from '../Resources/AbstractResource'
import { AbstractCollectionResource } from '../Resources/AbstractCollectionResource'

class AbstractClient {

  collectionType = AbstractCollectionResource

  _getResourceFromResponseData(data, type) {
    let collection = new type()
    let validator = new TypeValidator(data)
    if(validator.isDirty()) {
      return collection
    }
    data = Array.isArray(data) ? data : [data]
    data.forEach(item => {
      collection.push(collection.collects.make(item))
    })
    return collection
  }

  _returnCollection(response) {
    let data = response.data.data
    let items = this._getResourceFromResponseData(data, this.collectionType)

    return items
  }

  _returnFirst(collection) {
    return collection[0]
  }

  static parseData(data) {
    if(data instanceof FormData) {
      return data
    }

    if(data instanceof AbstractResource) {
      return data.toArray()
    }

    if(data.data !== undefined) {
      return data
    }

    return {
      data: data
    }
  }

  _handleErrorResponse(response) {
    if(response.response.data.success !== undefined) {
      return Promise.reject(response.response.data)
    }

    return response
  }

  constructor() {
    let error = ''

    let abstracts = [
      'indexUrl',
      'storeUrl',
      'showUrl',
      'updateUrl',
      'destroyUrl',
      'uri'
    ]

    abstracts.forEach(abstract => {
      if(this[abstract] === undefined) {
        error = `${abstract} must exist on class ${this.constructor.name}`
      }
    })

    if(error !== '') {
      throw new Error(error)
    }

    this.api_token = $('body').data('api-token')
  }

  indexUrl() {
    return this.uri
  }

  storeUrl() {
    return this.uri
  }

  showUrl(id) {
    return `${this.uri}/${id}`
  }

  updateUrl(id) {
    return `${this.uri}/${id}`
  }

  destroyUrl(id) {
    return `${this.uri}/${id}`
  }

  index() {
    let url = this.indexUrl()
    let obj = this.authenticate({ data: {} })

    return axios
      .get(url, {params: obj.data})
      .then(this._returnCollection.bind(this))
  }

  get(id) {
    let url = this.showUrl(id)

    let obj = this.authenticate({ data: {} })


    return axios.get(url, {params: obj.data})
      .then(this._returnCollection.bind(this))
      .then(this._returnFirst.bind(this))
  }

  show(...args) {
    return this.get(...args)
  }

  update(id, data) {
    let url = this.updateUrl(id)

    data = this.constructor.parseData(data)
    data = this.authenticate(data)

    if(data.data) {
      data = data.data
    }

    let result = axios
      .put(url, data)
      .then(this._returnCollection.bind(this))
      .then(this._returnFirst.bind(this))
      .catch(this._handleErrorResponse.bind(this))

    return result
  }

  destroy(id) {
    let url = this.destroyUrl(id)

    let obj = this.authenticate({ data: {} })

    return axios
      .delete(url, obj)
  }

  store(data) {
    let url = this.storeUrl()

    data = this.constructor.parseData(data)
    data = this.authenticate(data)

    if(data.data) {
      data = data.data
    }

    let res = axios
      .post(url, data)
      .then(this._returnCollection.bind(this))
      .then(this._returnFirst.bind(this))
      .catch(this._handleErrorResponse.bind(this))

    return res
  }

  authenticate(data) {
    if(data instanceof FormData) {
      data.append('api_token', this.api_token)
    } else {
      data.data.api_token = this.api_token
    }
    return data
  }

}


export { AbstractClient }