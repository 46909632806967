import { AbstractResource } from './AbstractResource'
import { TypeValidator } from '../Utilities/TypeValidator'
import * as _array from 'lodash/array'
import * as _collection from 'lodash/collection'
import { Proxifier } from '../Utilities/Proxifier'

/**
 * @method chunk - see lodash.Array,
 * @method compact - see lodash.Array
 * @method concat - see lodash.Array
 * @method difference - see lodash.Array
 * @method differenceBy - see lodash.Array
 * @method differenceWith - see lodash.Array
 * @method drop - see lodash.Array
 * @method dropRight - see lodash.Array
 * @method dropRightWhile - see lodash.Array
 * @method dropWhile - see lodash.Array
 * @method fill - see lodash.Array
 * @method findIndex - see lodash.Array
 * @method findLastIndex - see lodash.Array
 * @method first - see lodash.Array
 * @method flatten - see lodash.Array
 * @method flattenDeep - see lodash.Array
 * @method flattenDepth - see lodash.Array
 * @method fromPairs - see lodash.Array
 * @method head - see lodash.Array
 * @method indexOf - see lodash.Array
 * @method initial - see lodash.Array
 * @method intersection - see lodash.Array
 * @method intersectionBy - see lodash.Array
 * @method intersectionWith - see lodash.Array
 * @method join - see lodash.Array
 * @method last - see lodash.Array
 * @method lastIndexOf - see lodash.Array
 * @method nth - see lodash.Array
 * @method pull - see lodash.Array
 * @method pullAll - see lodash.Array
 * @method pullAllBy - see lodash.Array
 * @method pullAllWith - see lodash.Array
 * @method pullAt - see lodash.Array
 * @method remove - see lodash.Array
 * @method reverse - see lodash.Array
 * @method slice - see lodash.Array
 * @method sortedIndex - see lodash.Array
 * @method sortedIndexBy - see lodash.Array
 * @method sortedIndexOf - see lodash.Array
 * @method sortedLastIndex - see lodash.Array
 * @method sortedLastIndexBy - see lodash.Array
 * @method sortedLastIndexOf - see lodash.Array
 * @method sortedUniq - see lodash.Array
 * @method sortedUniqBy - see lodash.Array
 * @method tail - see lodash.Array
 * @method take - see lodash.Array
 * @method takeRight - see lodash.Array
 * @method takeRightWhile - see lodash.Array
 * @method takeWhile - see lodash.Array
 * @method union - see lodash.Array
 * @method unionBy - see lodash.Array
 * @method unionWith - see lodash.Array
 * @method uniq - see lodash.Array
 * @method uniqBy - see lodash.Array
 * @method uniqWith - see lodash.Array
 * @method unzip - see lodash.Array
 * @method unzipWith - see lodash.Array
 * @method without - see lodash.Array
 * @method xor - see lodash.Array
 * @method xorBy - see lodash.Array
 * @method xorWith - see lodash.Array
 * @method zip - see lodash.Array
 * @method zipObject - see lodash.Array
 * @method zipObjectDeep - see lodash.Array
 * @method zipWith - see lodash.Array
 */
class AbstractCollectionResource extends Array {

  collects = AbstractResource;

  static make() {
    return new this(...arguments)
  }

  constructor() {
    super()

    let t = this

    arguments.length && this.push(...arguments)

    t = Proxifier.wrap(this).with(_array)
    t = Proxifier.wrap(t).with(_collection)

    return t
  }

  _forwardCall(method, ...args) {
    return Array.prototype[method].apply(this, args)
  }

  _validateItem() {
    return Array.from(arguments).map((item) => {
      let validator = new TypeValidator(item)
      if(validator.isNotInstanceOf(this.collects)) {
        return new this.collects(item)
      }
      return item
    })
  }

  push() {
    let args = this._validateItem(...arguments)
    return this._forwardCall('push', ...args)
  }

  unshift() {
    let args = this._validateItem(...arguments)
    return this._forwardCall('unshift', ...args)
  }

  splice(start, delCount, ...items) {
    let args = this._validateItem(...items)
    return this._forwardCall('splice', start, delCount, ...args)
  }

  fill(item, start, end) {
    let args = this._validateItem(...arguments)
    return this._forwardCall('fill', ...args)
  }

  toJson() {
    let items = this.resolve()
    return JSON.stringify(items)
  }

  resolve() {
    let response = {
      data: []
    }

    for(let item of this) {
      response.data.push(item.toArray().data)
    }

    return response
  }
}

export { AbstractCollectionResource }