class Card
{

  static _name = 'card'
  static _selector = 'c-card'

  constructor (element) {
    this.element = element
  }

  boot () {

  }
}

export { Card }