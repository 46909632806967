import 'jquery-serializejson'
import { NotesCollectionResource } from '../Resources/NotesCollectionResource'
import { AbstractClient } from './AbstractClient'

/**
 * @method get(int id)
 * @method index()
 * @method store(QuoteHeaderResource data)
 * @method update(int id, QuoteHeaderResource data)
 * @method delete(int id)
 */
class NotesClient extends AbstractClient {

  collectionType = NotesCollectionResource

  get uri() {
    return '/api/quotes/headers/' + this.quote_header_id + '/notes'
  }

  constructor(quote_header_id) {
    super()
    this.quote_header_id = quote_header_id
  }

  store(data, agentId, entityId, entityType) {
    data = this.constructor.parseData(data)

    data.data.agent_id = agentId
    data.data.notable_id = entityId
    data.data.notable_type = entityType

    if(entityType === 'quote_item') {
      data.data.category = 'po_comment'
    }

    return super.store(data)
  }

}

export { NotesClient }