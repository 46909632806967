import { mix } from '../globals/Utilities/MixinBuilder'
import { HasCallbackQueues } from '../globals/Utilities/HasCallbackQueue'

class MediaSelector extends mix().with(HasCallbackQueues) {
  static _name = 'media-selector'
  static _selector = '.c-media-selector'

  constructor(element, ...args) {
    super(...args)
    this.element = element
    this.modal = $(element).find('.c-modal').first()
    this.tabs = $(element).find('.c-tabs').first()
    this.dragDropUploader = $(element).find('.c-drag-drop-uploader').first()
    this.datatable = $(element).find('.c-datatable').first()
    this.thumbnail = $(element).find('.thumbnail-wrapper').first()
    this.image = $(`<img src="" />`)
    this.input = $(element).find('input[type="hidden"]')

    this.thumbnail.append(this.image)
  }

  boot() {
    this.modal.on('modal.booted', () => {
      this._registerModalCallbacks()
    })
    this.dragDropUploader.on('dragdropuploader.booted', () => {
      this._registerDragDropCallbacks()
    })
    this.datatable.on('datatable.booted', () => {
      this._registerDatatableCallbacks()
    })
  }

  _registerModalCallbacks() {
  }

  _registerDragDropCallbacks() {
    this.dragDropUploader.data('dragdropuploader').registerCallback('uploaded', (e, file) => {
      this._addUploadToTable(file)
    }, 10)
  }

  _addUploadToTable(file) {
    let table = this.datatable.data('datatable').table

    let  existingRows = table.searchData('id', '=', file.data.id)

    if(existingRows.length) {
      this._selectLastRow()
      return
    }

    table.addData(file.data)
  }

  _registerDatatableCallbacks() {
    let datatable = this.datatable.data('datatable')
    datatable.registerCallback('rowAdded', (e, row) => {
      this._selectLastRow()
    })

    datatable.registerCallback('rowSelected', (row) => {
      this.modal.data('modal').close()
    })

    datatable.registerCallback('rowSelected', (row) => {
      this.setThumbnail(row.getData())
    })

    datatable.registerCallback('rowSelected', (row) => {
      this.modal.find('.c-button').first().addClass('submit')
    })
  }

  _selectLastRow() {
    let table = this.datatable.data('datatable').table

    table.deselectRow()
    table.selectRow(table.getRows().pop().getData().id)
  }

  setThumbnail(file) {
    this.image.attr('src', file.thumbnail)
    this.input.attr('value', file.id)
  }
}

export { MediaSelector }