import $ from 'jquery'
import { Utilities } from '../globals/Utilities.js'

class Alert extends Utilities(class {})
{

  static _selector = '.c-alert'
  static _name = 'alert'

  constructor (element) {
    super()
    this.element = element
  }

  boot () {
    this.bindEvents()
  }

  bindEvents () {
    $(this.element).find('.button-close').on('click', () => {
      this.dismiss(this.element)
    })
  }

  dismiss () {
    $(this.element).hide()
  }

  display () {
    $(this.element).fadeIn(250)
  }
}

export { Alert }

