import $ from 'jquery'
import { ManagesObjects } from './Utilities/ManagesObjects'

let Utilities = Base => class extends Base
{

  dismiss (element) {
    $(element).hide()
  }

  display (element) {
    $(element).fadeIn(250)
  }

}

window.ManagesObjects = ManagesObjects

export {
  Utilities
}