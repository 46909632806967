import { AbstractResource } from './AbstractResource'

class DenyMessageResource extends AbstractResource {

  static keys = {
    'id': Number,
    'message': String,
    'is_active': Boolean,
    'quote_header_id': Number,
    'admin_agent_id': Number,
  }

}

export { DenyMessageResource }