class ProgressStep{
    constructor(element) {

    }

    boot() {

    }
}

ProgressStep._name = 'progress-step'
ProgressStep._selector = '.c-progress-step'

export {ProgressStep}