class Page
{
  static _name = 'page'
  static _selector = '.c-page'

  constructor (element) {
    this.element = element
  }

  boot () {
    $('body').addClass('has-page')
  }
}

export { Page }