import $ from 'jquery'

import { QuoteCreatePage } from './QuoteCreatePage'
import { QuoteItemsCreatePage } from './QuoteItemsCreatePage'
import { QuoteShowPage } from './QuoteShowPage'
import { QuoteIndexPage } from './QuoteIndexPage'

class Pages {

  constructor (pages) {
    this.pages = pages
  }

  static register () {
    return new this([
      QuoteCreatePage,
      QuoteItemsCreatePage,
      QuoteShowPage,
      QuoteIndexPage
    ])
  }

  boot () {
    $(() => {
      this.pages.forEach((c) => {
        let elms = document.querySelectorAll(c._selector)

        let elmsArr = [].slice.call(elms)

        elmsArr.forEach(function (el) {
          let inst = new c(el)

          $(el).data(c._name, inst)
          inst.boot()
        })
      })

      $('body').trigger('js.pages.loaded')
    })
  }
}

export { Pages }