import 'jquery-serializejson'
import { DenyMessagesCollectionResource } from '../Resources/DenyMessagesCollectionResource'
import { AbstractClient } from './AbstractClient'

/**
 * @method get(int id)
 * @method index()
 * @method store(QuoteHeaderResource data)
 * @method update(int id, QuoteHeaderResource data)
 * @method delete(int id)
 */
class DenyMessagesClient extends AbstractClient {

  collectionType = DenyMessagesCollectionResource

  get uri() {
    return '/api/quotes/headers/' + this.quote_header_id + '/messages'
  }

  constructor(quote_header_id) {
    super()
    this.quote_header_id = quote_header_id
  }
  
}

export { DenyMessagesClient }