import { QuoteHeadersClient } from '../globals/Clients/QuoteHeadersClient'
import { Route } from '../globals/Utilities/Route'
import { QuotePhasesClient } from '../globals/Clients/QuotePhasesClient'
import Fuse from 'fuse.js'

class QuoteIndexPage {


  static _name = 'quotes-index'
  static _selector = '.p-quotes-index'

  constructor(element) {
    this.element = element
    this.userId = $(this.element).data('user-id')
    this.quoteHeadersClient = new QuoteHeadersClient()
    this.quotePhasesClient = new QuotePhasesClient()
  }

  boot() {
    this.bindEvents()
  }

  bindEvents() {
    $('#show-all-quotes .c-card', this.element).on('assume-quote', (e, data) => {
      this.assumeQuoteForReview(data.button.quoteId)
    })

    $('#show-all-quotes .c-card', this.element).on('decrement-phase', (e, data) => {
      this.decrementQuotePhase(data.button.quoteId)
    })

    $('#search', this.element).on('change', (e) => {
      this.filterQuotes()
    })
  }

  assumeQuoteForReview(quoteId) {
    let quote = this.quoteHeadersClient.get(quoteId)
    let phases = this.quotePhasesClient.index()

    Promise.all([quote, phases])
      .then(([quote, phases]) => {

        let sorted = phases.sortBy(['position'])

        let idx = sorted.findIndex(item => item.position === quote.phase.position)

        let next = sorted[idx + 1]

        quote.quote_phase_id = next.id

        return this.quoteHeadersClient.update(quoteId, quote, this.userId)
      })
      .then(() => Route.redirectTo(`/quotes/headers/${quoteId}`))
  }

  decrementQuotePhase(quoteId) {
    let phases = this.quotePhasesClient.index()
    let quote = this.quoteHeadersClient.get(quoteId)

    Promise.all([quote, phases])
      .then(([quote, phases]) => {
        let currentPosition = quote.phase.position

        let currentPhaseIndex = phases
          .sortBy(['position'])
          .findIndex(item => item.position === currentPosition)

        let previousPhase = phases[currentPhaseIndex - 1]

        return Promise.all([previousPhase, quote])
      })
      .then(([phase, quote]) => {
        quote.quote_phase_id = phase.id
        return this.quoteHeadersClient.update(quoteId, quote, this.userId)
      })
      .then(() => Route.redirectTo(`headers/${quoteId}`))
  }

  filterQuotes() {
    let search_text = $('#search').val()
    let quotes = $('.quote-card')
    let search_data = $('.quote-card').map((i, el) => $(el).data('quote'))

    quotes.each(function(item) { $(this).show() })

    let options = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: [
        "id",
        "notes",
        "decorator_number",
        "sales_agent_id",
        "administrative_agent_id",
        "customer.number",
        "customer.email",
        "customer.sales_agent_number",
        "customer.phone",
        "customer.name",
        "customer.address_line_1",
        "customer.address_line_2",
        "customer.address_line_3",
        "customer.city",
        "customer.state",
        "customer.zip",
        "customer.country",
        "supplier.number",
        "supplier.name",
        "supplier.phone",
        "supplier.email",
        "administrativeAgent.name",
        "administrativeAgent.email",
        "salesAgent.name",
        "salesAgent.email"
      ]
    };

    let fuse = new Fuse(search_data, options) // "list" is the item array
    let result = fuse.search(search_text)

    quotes.each(function(index, elem) {
      for(let i = 0; i < result.length; i++) {
        if($(this).data('quote').id === result[i].id) {
          break
        }
        if(i === result.length - 1) {
          $(this).hide()
        }
      }
    })
  }
}

export { QuoteIndexPage }