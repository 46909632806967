class ItemPriceCalculator {
  quantity = null
  unit_cost = null
  run_charge_gross = null
  commission_percent = null
  margin = null

  constructor(commission_percent, margin, quantity, unit_cost, run_charge_gross) {
    this.quantity = quantity
    this.unit_cost = unit_cost
    this.run_charge_gross = run_charge_gross
    this.commission_percent = commission_percent
    this.margin = margin/100
  }

  price_per_unit() {
    return Number(this.unit_cost / this.margin)
  }

  sale_price_per_unit() {
    return Number(( this.unit_cost / this.margin) + this.run_charge_gross )
  }

  sale_price_total() {
    return Number(((this.unit_cost / this.margin) * this.quantity) + (this.run_charge_gross * this.quantity))
  }
}

export { ItemPriceCalculator }