import Tabulator from 'tabulator-tables'
import { Proxifier } from '../globals/Utilities/Proxifier'
import * as _collection from 'lodash/collection'
import * as _array from 'lodash/array'
import { Route } from '../globals/Utilities/Route'
import axios from 'axios'

class DataTable {

  static _name = 'datatable'
  static _selector = '.c-datatable'

  static requestCache = {}

  element = null
  options = null
  settings = null
  url = null
  columnDefinitions = null
  table = null

  callbacks = {}
  formatters = {}

  constructor(element) {

    this.element = element
    this.url = $(this.element).data('url')
    this.columnDefinitions = $(this.element).data('columns') || false
    this.options = $(this.element).data('options')
    this.table = new Tabulator($(this.element).find('.table-wrapper').get(0), this.getSettings())
    this.addRowButton = $('.add-row-button', this.element)

    return Proxifier.forward(this).to(this.table)
  }

  getSettings() {
    if(this.settings) {
      return this.settings
    }

    this.settings = $.extend(
      this.defaultOptions,
      this.options
    )

    if( this.columnDefinitions ) {
      this.settings = $.extend(
        this.settings,
        {
          columns: this.columnDefinitions
        }
      )
    } else {
      this.settings.autoColumns = true
    }

    return this.settings
  }

  boot() {
    this._bootInitialCallbacks()

    if($(this.element).data('searchable')) {
      this._enableSearch()
    }

    if($(this.element).data('trashable')) {
      this._enableTrashable()
    }

    if($(this.element).data('appendable')) {
      this._enableAppendable()
    }

    this._enableEditable()

    this._addRequestToCache()

    this._loadData()
  }

  _bootInitialCallbacks() {
    this.registerCallback('ajaxResponse', (...args) => this._ajaxResponseHandler(...args), Number.NEGATIVE_INFINITY) // always run first

    this.registerCallback('rowSelected', (...args) => {
      this._rowSelectedHandler(...args)
      this._genericEmitHandler('row.selection.select', ...args)
    }, Number.NEGATIVE_INFINITY)

    this.registerCallback('rowDeselected', (...args) => {
      this._rowDeselectedHandler(...args)
      this._genericEmitHandler('row.selection.deselected', ...args)
    }, Number.NEGATIVE_INFINITY)

    this.registerCallback('rowSelectionChanged', (...args) => {
      this._rowSelectionChangedHandler(...args)
      this._genericEmitHandler('row.selection.change', ...args)
    }, Number.NEGATIVE_INFINITY)
  }

  _genericEmitHandler(event, ...args) {
    this.emit(event, [{
      ...args,
      table: this.table,
      datatable: this
    }])
  }

  _rowSelectedHandler() {
    this.emit('row-selected')
  }

  _rowDeselectedHandler() {
    this.emit('row-deselected')
  }

  _rowSelectionChangedHandler() {
    this.emit('row-selection-changed')
  }

  _ajaxResponseHandler(url, params, response, previousValue) {
      previousValue = previousValue || response
      this.emit('parsing-response', previousValue)
      this.emit('ajax.response', [{
        previousValue,
        params,
        table: this.table,
      }])

      if(previousValue.data !== undefined) {
      return previousValue.data
    }

    if(previousValue instanceof Array) {
      return previousValue
    }

    return [previousValue]
  }

  /**
   * Sets a single request per url. If multiple datatables exist on the apge for a single url, they will all source from the same response
   * @private
   */
  _addRequestToCache() {
    let token = $('body').data('api-token')
    let url = Route.getAsTag(this.url)
    url = url.origin + url.pathname
    let params = Route.objectFromUrlQuery(this.url)
    params.api_token = token

    this.disable()
    if(this.constructor.requestCache[this.url] !== undefined) {
      return
    }

    this.constructor.requestCache[this.url] = axios.get(url, {
      params
    })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  _loadData() {
    this.constructor.requestCache[this.url].then((data) => {

      if(data.hasOwnProperty('data')) {
        data = data.data
      }

      this.table.clearData()

      this.table.setData(data)
        .then(() => {
          this.columnDefinitions = this.table.getColumnDefinitions()
          this.setColumns(this.columnDefinitions)
        })
        .then(() => {
          this.enable()
          this.emit('data.load', [{
            data: this.table.getData(),
            table: this.table,
            datatable: this
          }])
        })
    })
  }

  _enableSearch() {
    this.searchInput = $(this.element).children('label').find('input')

    this.table.dataFiltered = (filters, rows) => {
      this.emit('row')
    }

    this.searchInput.on('keyup search', e => {
      if(e.keyCode === 8) {
        this.table.clearFilter()
      }

      let fields = $(this.element).data('searchable')
      let value = this.searchInput.val()
      let type = 'like'

      fields = fields.map(field => {
        return {
          field: field,
          type: type,
          value: value
        }
      })

      this.table.setFilter([fields])
    })
  }

  _enableTrashable() {
    let formatter = () => {
      return `<i class="fa fa-trash-alt" style="color: #f45f4f;"></i>`
    }

    this.columnDefinitions.unshift({
        formatter: formatter,
        width: 25,
        align: 'center',
        headerSort:false,
        cellClick: (event, cell) => {
          this.emit('delete-row', cell.getRow().getData())
          this.emit('row.delete', [{
            event,
            row: cell.getRow(),
            data: cell.getRow().getData(),
            table: this.table,
            datatable: this
          }])
          cell.getRow().delete()
        }
    })

    this.setColumns(this.columnDefinitions)
  }

  _enableEditable() {
    let newDefinitions = this.columnDefinitions.map(column => {
      if(!column.hasOwnProperty('editor')) {
        return column
      }

      column.cellEdited = cell => {
        this.emit('edited-row', {
          cell: cell,
          column: cell.getColumn(),
          row: cell.getRow(),
          datatable: this
        })


        this.emit('cell.edit', [{
          cell,
          table: this.table,
          datatable: this
        }])

        cell.getRow().reformat()
      }

      return column
    })

    this.columnDefinitions = newDefinitions

    this.table.setColumns(this.columnDefinitions)
  }

  _enableAppendable() {
    $(this.element).on('add-row-button-click', e => {
      let data = {}
      this._genericEmitHandler('row.append.before', data)

      this.table.addRow(data)
        .then(row => {
          this._genericEmitHandler('row.append.after', row)
        })
    })
  }

  setColumns(definitions) {
    this.columnDefinitions = definitions

    this.emit('column-definitions', [this.columnDefinitions])
    this.emit('settings.column-definitions', [{
      columnDefinitions: this.columnDefinitions,
      table: this.table,
      datatable: this
    }])

    this.table.setColumns(this.columnDefinitions)
  }

  emit(event, data) {
    $(this.element).trigger(event, data || this)
  }

  addData(data) {
    if( false === (data instanceof Array) ) {
      data = [data]
    }
    this.table.addData(data)
    return this
  }

  getFields() {
    return this.table.columnManager.columns.map(column => {
      return column.field
    })
  }

  registerCallback(option, callback, weight = 10) {
    if( this.callbacks[option] === undefined ) {
      this.callbacks[option] = []
    }

    let definition = {
      func: callback,
      weight: weight
    }

    this.callbacks[option].push(definition)

    this._setRegisteredCallbacks()
  }

  removeCallback(option, callback) {
    if( this.callbacks[option] === undefined ) {
      this.callbacks[option] = []
    }

    this.callbacks[option] = _collection.reject(this.callbacks[option], callbackDefinition => {
      return callbackDefinition.func === callback
    })

    this._setRegisteredCallbacks()
  }

  _setRegisteredCallbacks() {
    for(let key in this.callbacks) {
      if(!this.callbacks.hasOwnProperty(key)) {
        continue
      }

      this.table.options[key] = (...args) => {
        let finalResult
        let sorted = _collection.sortBy(this.callbacks[key], i => i.weight)

          _collection.each(sorted, callbackDefinition => {
            finalResult = callbackDefinition.func(...args, finalResult)
          })

        return finalResult
      }
    }
  }

  getColumnDefinitionIndex(column) {
    column = this.getColumnDefinition(column)
    return _array.findIndex(this.columnDefinitions, column)
  }

  getColumnDefinition(column) {

    if( typeof column === 'object' ) {
      column = _collection.find(this.columnDefinitions, column)
    }

    if( typeof column === 'string' ) {
      column = _collection.find(this.columnDefinitions, {'field': 'artwork'})
    }

    if( typeof column === 'number' ) {
      column = this.columnDefinitions[column]
    }

    return column
  }

  registerFormatter(column, callback, weight = 10) {
    column = this.getColumnDefinition(column)
    let columnIndex = this.getColumnDefinitionIndex(column)

    let formatterDefinition = {
      weight,
      column,
      callback
    }

    if(!this.formatters[columnIndex]) {
      this.formatters[columnIndex] = []
    }
    this.formatters[columnIndex].push(formatterDefinition)

    this._setRegisteredFormatters()
  }

  removeFormatter(column, callback) {
    column = this.getColumnDefinition(column)
    let columnIndex = this.getColumnDefinition(column)

    let formatterDefinition = {
      column,
      callback
    }
    if( this.formatters[columnIndex] === undefined ) {
      this.formatters[columnIndex] = []
    }

    this.formatters[columnIndex] = _collection.reject(this.formatters[columnIndex], formatterDefinition => {
      return formatterDefinition.func === callback
    })

    this._setRegisteredFormatters()
  }

  _setRegisteredFormatters() {
    for(let columnIndex in this.formatters) {
      if(!this.formatters.hasOwnProperty(columnIndex)) {
        continue
      }

      this.columnDefinitions[columnIndex].formatter = (...args) => {
        let finalResult

        _collection.each(this.formatters[columnIndex], callbackDefinition => {
          finalResult = callbackDefinition.callback(...args, finalResult)
        })

        return finalResult
      }
    }

    this.table.setColumns(this.columnDefinitions)
  }

  disable() {
    this.addRowButton.prop('disabled', true)
    $('.disabler', this.element).fadeIn(50)
  }

  enable() {
    this.addRowButton.prop('disabled', false)
    $('.disabler', this.element).fadeOut(50)
  }

  reload() {
    this._addRequestToCache()
    this._loadData()
  }
}

export { DataTable }