import dragDrop from 'drag-drop'
import { FileUploadClient } from '../globals/Clients/FileUploadClient'
import { HasCallbackQueues } from '../globals/Utilities/HasCallbackQueue'
import { mix } from '../globals/Utilities/MixinBuilder'

class DragDropUploader extends mix().with(
  HasCallbackQueues
) {

  static _name = 'dragdropuploader'
  static _selector = '.c-drag-drop-uploader'

  constructor(element) {
    super(...arguments)
    this.element = element
    this.form = $('form', this.element)
    this.zone = $('.zone', this.element)
    this.dragDrop = dragDrop(this.zone.get(0), {
      onDrop: this._getCallbackFor('drop'),
      onDragEnter: this._getCallbackFor('dragEnter'),
      onDragOver: this._getCallbackFor('dragOver'),
      onDragLeave: this._getCallbackFor('dragLeave')
    })
    this.filesClient = new FileUploadClient()
  }

  _getCallbackFor(name) {
    let t = this
    return (...args) => t['_' + name](...args)
  }

  boot() {
    this.registerCallback('drop', this._uploadFile.bind(this))
  }

  _drop(...args) {
    this.runCallbackQueue('drop', ...args)
  }

  _dragEnter(...args) {
    this.runCallbackQueue('drag-enter', ...args)
  }

  _dragOver(...args) {
    this.runCallbackQueue('drag-over', ...args)
  }

  _dragLeave(...args) {
    this.runCallbackQueue('drag-leave', ...args)
  }

  _uploadFile(result, files, ...args) {

    if(!files.length) {
      return
    }

    this.zone.addClass('uploading')

    files.forEach((file, i) => {
      let formData = new FormData()
      formData.append('file', file)

      this.filesClient.store(formData)
        .then((file) => {
          this.runCallbackQueue('uploaded', file)
          this.zone.removeClass('uploading')
            .addClass('upload-success')
            .delay(3000)
            .queue(function() {
              $(this).removeClass('upload-success')
              $(this.dequeue())
            })
        })
        .catch(() => {
          this.zone.removeClass('uploading')
            .addClass('upload-failed')
            .delay(3000)
            .queue(function() {
              $(this).removeClass('upload-failed')
              $(this.dequeue())
            })
        })
    })
  }
}

export { DragDropUploader }