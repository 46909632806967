import $ from 'jquery'

import { LineItemDatatable } from './Datatable/LineItemDatatable'
import { EmSheetDatatable } from './Datatable/EmSheetDatatable'

class Compositions {

  static forceBoot(el) {
    this.compositionList().forEach((compositionCls) => {
      if ($(el).is(compositionCls._selector)) {
        let compositionInstance = new compositionCls(el)
        $(el).data(compositionCls._name, compositionInstance)
        compositionInstance.boot()
      }
    })
  }

  constructor (compositions) {
    this.compositions = compositions
  }

  static compositionList() {
    return [
      LineItemDatatable,
      EmSheetDatatable
    ]
  }

  static register () {
    return new this(Compositions.compositionList())
  }

  boot () {
    $(() => {
      this.compositions.forEach((c) => {
        let elms = document.querySelectorAll(c._selector)

        let elmsArr = [].slice.call(elms)

        elmsArr.forEach(function (el) {
          let inst = new c(el)

          $(el).data(c._name, inst)
          inst.boot()
        })
      })

      $('body').trigger('js.compositions.loaded')
    })
  }
}

export { Compositions }