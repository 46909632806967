import $ from 'jquery'
import { AccordionMenu as FoundationAccordionMenu } from 'foundation-sites/js/foundation.accordionMenu.js'

class AccordionMenu
{
  static _name = 'accordion-menu'
  static _selector = '.c-accordion-menu'

  constructor (element) {
    this.element = element
  }

  boot () {
    this.foundationAccordionMenu = new FoundationAccordionMenu($('ul', this.element).first())
  }
}

export { AccordionMenu }