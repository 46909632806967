class ProgressBar
{
  static _name = 'progress-bar'
  static _selector = '.c-progress-bar'

  constructor (element) {
    this.element = element
  }

  boot () {

  }
}

export { ProgressBar }