class Proxifier {
  static wrap(target) {
    return {
      with(library) {
        const handler = {
          get: function(target, property) {
            if(target[property] !== undefined) {
              return target[property]
            }
            if(library[property] !== undefined) {
              return function(...args) {
                let forward = library[property].bind(target)
                return forward(target, ...args)
              }.bind(target)
            }
            return undefined
          }
        }
        return new Proxy(target, handler)
      }
    }
  }

  static forward(target) {
    return {
      to(library) {
        const handler = {
          get: function(target, property) {
            if(target[property] !== undefined) {
              return target[property]
            }
            if(library[property] !== undefined) {
              return library[property]
            }
            return undefined
          }
        }
        return new Proxy(target, handler)
      }
    }
  }
}

export { Proxifier }