import { AbstractResource } from './AbstractResource'
import { QuoteHeaderResource } from './QuoteHeaderResource'
import { FileUploadResource } from './FileUploadResource'

class ShippingEntryResource extends AbstractResource {

  static keys = {
    'id' : Number,
    'type' : String,
    'quote_header_id' : Number,
    'file_upload_id': null,
    'ship_to_name' : String,
    'street_1' : String,
    'street_2' : String,
    'street_3' : String,
    'street_4' : String,
    'city' : String,
    'state' : String,
    'zip' : String,
    'country' : String,
    'file_upload' : FileUploadResource,
    'quote_header': QuoteHeaderResource,
    'shipping_method': String,
    'account_number': String,
    'tracking_number': String,
    'ship_date': Date,
    'customer_po_number': String
  }
}

export { ShippingEntryResource }