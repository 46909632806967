import 'jquery-serializejson'
import { QuoteItemArtworksCollectionResource } from '../Resources/QuoteItemArtworksCollectionResource'
import { AbstractClient } from './AbstractClient'
/**
 * @method get(int id)
 * @method index()
 * @method store(QuoteItemArtworkResource data)
 * @method update(int id, QuoteItemArtworkResource data)
 * @method delete(int id)
 */
class QuoteItemArtworksClient extends AbstractClient {

  collectionType = QuoteItemArtworksCollectionResource

  get uri() {
    return '/api/quotes/artwork'
  }

}

export { QuoteItemArtworksClient }