import 'jquery-serializejson'
import { QuoteHeadersCollectionResource } from '../Resources/QuoteHeadersCollectionResource'
import { AbstractClient } from './AbstractClient'
import axios from 'axios/index'

/**
 * @method get(int id)
 * @method index()
 * @method store(QuoteHeaderResource data)
 * @method update(int id, QuoteHeaderResource data)
 * @method delete(int id)
 */
class QuoteHeadersClient extends AbstractClient {

  collectionType = QuoteHeadersCollectionResource

  get uri() {
    return '/api/quotes/headers'
  }

  constructor() {
    super()
  }

  update(id, data) {
    let url = this.updateUrl(id)

    data = this.constructor.parseData(data)

    return super.update(id, data)
  }

  sendToMacola(id) {
    let url = this.macolaUrl(id)
    let data = {data: {}}

    data = this.constructor.parseData(data)
    data = this.authenticate(data)

    if(data.data) {
      data = data.data
    }

    let res = axios
      .post(url, data)
      .then(this._returnCollection.bind(this))
      .then(this._returnFirst.bind(this))
      .catch(this._handleErrorResponse.bind(this))

    return res
  }

  macolaUrl(id) {
    return `${this.uri}/${id}` + '/sendToMacola'
  }
}

export { QuoteHeadersClient }