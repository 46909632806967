import 'jquery-serializejson'
import { AbstractClient } from './AbstractClient'
import { FileUploadsCollectionResource } from '../Resources/FileUploadsCollectionResource'
import axios from 'axios'

/**
 * @method get(int id)
 * @method index()
 * @method store(QuotePhaseResource data)
 * @method update(int id, QuotePhaseResource data)
 * @method delete(int id)
 */
class FileUploadClient extends AbstractClient {

  collectionType = FileUploadsCollectionResource

  get uri() {
    return '/api/files'
  }

  get(id) {
    let url = this.showUrl(id)

    let obj = this.authenticate({ data: {} })
    obj.data.id = id

    return axios.get(url, {params: obj.data})
      .then(this._returnCollection.bind(this))
      .then(this._returnFirst.bind(this))
  }
}

export { FileUploadClient }