import * as _collection from 'lodash/collection'

const HasCallbackQueues = superclass => class extends superclass {
  queues = {}
  queueKey = ''

  constructor(...args) {
    super(...args)
    this.queueKey = this.constructor._name
    this.queues[this.queueKey] = []
  }

  registerCallback(queue, callback, weight = 10) {
    let descriptor = {
      callback,
      weight
    }

    this.setCallbackQueue(queue)

    this.queues[this.queueKey][queue].push(descriptor)
  }

  removeCallback(queue, callback) {
    this.setCallbackQueue(queue)
    this.queues[this.queueKey][queue] = _collection.reject(
      this.queues[this.queueKey][queue],
      {callback}
    )
  }

  runCallbackQueue(queue, ...args) {
    let result

    queue = _collection.sortBy(this.getCallbackQueue(queue), ['weight'])

    return queue.reduce((result, descriptor) => {
      return descriptor.callback(result, ...args)
    }, result)
  }

  getCallbackQueueNames() {
    return Object.keys(this.queues[this.queueKey])
  }

  getCallbackQueues() {
    return this.queues[this.queueKey]
  }

  getCallbackQueue(queue) {
    return this.queues[this.queueKey][queue]
  }

  setCallbackQueue(queue) {
    this.queues[this.queueKey][queue] = this.queues[this.queueKey][queue] || []
  }

  clearCallbackQueue(queue) {
    this.queues[this.queueKey][queue] = []
  }
}

export { HasCallbackQueues }