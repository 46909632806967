import { AbstractResource } from './AbstractResource'

class FileUploadResource extends AbstractResource {

  static keys = {
    'id': Number,
    'location': String,
    'mime_type': String,
    'title': String,
    'url': String,
    'thumbnail': String
  }

}

export { FileUploadResource }