import { AbstractResource } from './AbstractResource'
import { QuoteHeaderResource } from './QuoteHeaderResource'
import { UserResource } from './UserResource'

class NoteResource extends AbstractResource {

  static keys = {
    'id': Number,
    'note': String,
    'quote_header': QuoteHeaderResource,
    'agent': UserResource,
    'created_at': Date,
    'category': String
  }

}

export { NoteResource }