import { AbstractCollectionResource } from './AbstractCollectionResource'
import { TypeValidator } from '../Utilities/TypeValidator'

class AbstractResource {

  static keys = {}

  static make(params) {
    return new this(params)
  }

  data = {}

  constructor(data = {}) {
    this.#defineGetters()
    this.fill(data)
  }

  #defineGetters() {
    Object.getOwnPropertyNames(this.constructor.keys).forEach((key) => {
      let type = this.constructor.keys[key]
      Object.defineProperty(this, key, {
        configurable: false,
        enumerable: false,
        get() {
          return this.data[key]
        },
        set(val) {
          let valueValidator = new TypeValidator(val)
          let typeValidator = new TypeValidator(type)

          if(valueValidator.isDirty()) {
            if(this.data.hasOwnProperty(key)) {
              delete this.data[key]
            }

            return val
          }

          if(typeValidator.isDirty()) {
            this.data[key] = val
            return this.data[key]
          }

          if(typeValidator.is(AbstractCollectionResource)) {
            let temp = new type()
            val.forEach(item => temp.append(item))
            val = temp
          }

          if(valueValidator.isNotA(type)) {
              val = new type(val)
          }

          this.data[key] = val
          return this.data[key]
        }
      })
    })
  }

  fill(data) {
    Object.keys(data).forEach(k => {
      if(!this.constructor.keys.hasOwnProperty(k)) {
        return
      }

      this[k] = data[k]
    })
  }

  #resolve() {
    let data = this.data
    for(let key in data) {

      if(!data.hasOwnProperty(key)) {
        continue
      }

      if(!this.constructor.keys.hasOwnProperty(key)) {
        continue
      }

      let val = data[key]

      let validation = new TypeValidator(val)

      if(validation.isDirty()) {
        val = undefined
      }

      if(validation.isInstanceOf(AbstractResource)) {
        val = val.toArray().data
      }

      if(validation.isInstanceOf(AbstractCollectionResource)) {
        val = val.resolve().data
      }

      // For Strings and Numbers, return their unwrapped values
      if(val.valueOf) {
        val = val.valueOf()
      }

      data[key] = val
    }

    return {
      data: data
    }
  }

  toArray() {
    let data = this.#resolve()

    return data
  }

  toJson() {
    let items = this.toArray()
    return JSON.stringify(items)
  }
}

export { AbstractResource }