import { AbstractResource } from './AbstractResource'
import { QuoteHeaderResource } from './QuoteHeaderResource'
import { QuoteItemArtworksCollectionResource } from './QuoteItemArtworksCollectionResource'
import { NotesCollectionResource } from './NotesCollectionResource'

class QuoteItemResource extends AbstractResource {

  static keys = {
    "id": Number,
    "quote_header": QuoteHeaderResource,
    "product_type": String,
    "style_number": String,
    "color": String,
    "product_link": String,
    "commission_percent": Number,
    "unit_material_charge": Number,
    "size": String,
    "quantity": Number,
    "artwork": Array,
    "run_charge_gross": Number,
    "run_charge_net": Number,
    "product_name": String,
    "comments": Array
  }
}

export { QuoteItemResource }