import { AbstractResource } from './AbstractResource'

class UserResource extends AbstractResource {

  static keys = {
    "id": Number,
    "name": String,
    "email": String,
    "email_verified_at": String,
    "created_at": String,
    "updated_at": String,
    "deleted_at": String,
    "sales_agent_number": String
  }
}

export { UserResource }