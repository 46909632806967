class TitleBar
{
  static _name = 'title-bar'
  static _selector = '.c-title-bar'

  constructor (element) {

  }

  boot () {

  }
}

export { TitleBar }