import $ from 'jquery'
import { Components } from './_base'
import axios from 'axios'
import { Route } from '../globals/Utilities/Route'

class Button
{

  static _name = 'button'
  static _selector = '.c-button'

  constructor (element) {
    this.element = element
    this.button = $('button', this.element)
  }

  boot () {
    this.bindEvents()
  }

  getMethod() {
    let methods = {
      link: this.redirectTo,
      open: this.open,
      close: this.close,
      toggle: this.toggle,
      remove: this.remove,
      add: this.add,
      event: this.event,
      clone: this.clone,
      submit: this.submit
    }
    if(methods.hasOwnProperty(this.button.data('method'))) {
      return methods[this.button.data('method')]
    }
    return false
  }

  getAction() {
    return this.button.data('action')
  }

  getTarget() {
    let target = this.button.data('target')
    if(target === 'window') {
      return window
    }

    if(target.includes('(') && target.includes(')')) {
      let func = "(" +
        "function() {" +
          "return " + target +
        ";})"
      let f = eval(func)
      let el = f.apply(this.button)
      return el
    }
    return $(target)
  }

  bindEvents() {
    this.button.on('click', (e) => {
      e.preventDefault()
      this.trigger()
    })
  }

  trigger() {
    let method = this.getMethod()
    let action = this.getAction()
    let target = this.getTarget()

    method.apply(this, [target, action])

    target.trigger('button-click', {
      action: action,
      method: method.name,
      data: this.button.data(),
      target: target
    })
  }

  redirectTo(target, action) {
    let a = document.createElement('a')
    a.href = action
    target.location = a.href
  }


  event(target, action, additionData = {}) {

    var data = {
      'button': $.extend({element: $(this.element)}, $(this.button).data(), {component: this} , additionData),
      'target': $.extend({element: $(target)}, $(target).data(), additionData)
    }

    target.trigger(action, data)
  }

  close(target, action) {
    if(!action) {
      target.fadeOut(200)
      return
    }

    target
      .removeClass(action)
  }

  open(target, action) {
    if(!action) {
      target.fadeIn(200)
      return
    }
    target
      .addClass('open')
  }

  submit(target, action) {
    if(!target.get(0).checkValidity()) {
      target.get(0).reportValidity()
      return
    }

    if (action) {
      target.submit((e) => {
        e.preventDefault()

        let json = target.serializeJSON()
        let method = target.attr('method')
        let endpoint = target.attr('action')
        let api_token = $('body').data('api-token')

        json.api_token = api_token

        if(json._method) {
          method = json._method
        }

        if(json.api_token) {
          endpoint = Route.attachQueryToUrl(endpoint, {api_token: json.api_token})
        }

        axios[method.toLowerCase()](endpoint, json)
          .then((response) => {
            this.event($('body'), action, {response})
            this.event($(this.element), action, {response})
          })
      })
    }

    $(this.element).addClass('disabled')

    target.submit()
  }

  toggle(target, action) {
    if(!action) {
      target.fadeToggle(200)
      return
    }

    target.toggleClass(action)
  }

  remove(target, action) {
    if(action) {
      target.removeClass(action)
      return
    }
    target.remove()
  }

  loading(callback = Promise.resolve()) {
    $(this.element).addClass('loading')

    return callback.then((...args) => {
          $(this.element)
            .removeClass('loading failed')
            .addClass('successful')
            .delay(1000)
            .queue(() => {
              $(this.element).removeClass('successful')
              $(this.element).dequeue()
            })
          return Promise.resolve(...args)
        })
        .catch((...args) => {
          $(this.element)
            .removeClass('loading successful')
            .addClass('failed')
            .delay(1000)
            .queue(() => {
              $(this.element).removeClass('failed')
              $(this.element).dequeue()
            })
          return Promise.reject(...args)
        })
  }

  disable(callback = Promise.resolve()) {
    $(this.element).addClass('disabled')

    return callback.then((...args) => {
      $(this.element)
        .removeClass('disabled')
        return Promise.resolve(...args)
    })
      .catch((...args) => {
        $(this.element)
          .removeClass('disabled')
        return Promise.resolve(...args)
      })
  }


}

export { Button }