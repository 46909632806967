import $ from 'jquery'

import { AppTemplate } from './AppTemplate'

class Templates
{

  constructor (templates) {
    this.templates = templates
  }

  static register () {
    return new this([
      AppTemplate
    ])
  }

  boot () {
    $(() => {
      this.templates.forEach((c) => {
        let elms = document.querySelectorAll(c._selector)

        let elmsArr = [].slice.call(elms)

        elmsArr.forEach(function (el) {
          $(el).data(c._name, new c(el))
        })
      })
    })
  }
}

export { Templates }