import $ from 'jquery'
import { DropdownMenu as FoundationDropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu.js'

class DropdownMenu
{
  static _name = 'dropdown-menu'
  static _selector = '.c-dropdown-menu'

  constructor (element) {
    this.element = element
  }

  boot () {
    this.foundationDropdownMenu = new FoundationDropdownMenu($('ul', this.element).first())
  }
}

export { DropdownMenu }