import $ from 'jquery'
import { mix } from '../globals/Utilities/MixinBuilder'
import { HasCallbackQueues } from '../globals/Utilities/HasCallbackQueue'

class Modal extends mix().with(HasCallbackQueues)
{

  static _name = 'modal'
  static _selector = '.c-modal'

  constructor (element, ...args) {
    super(...args)
    this.element = element
    this.backdrop = $('.backdrop', this.element)
    this.openButton = $('.c-button', this.element).first()
  }

  boot () {
    this.bindEvents()
  }

  bindEvents() {
    $(this.element).on('button-click', (e) => {
      if(e.target !== this.element) {
        return
      }
      this.renderContent()
    })

    this.backdrop.on('click', (e) => {
      e.preventDefault()
      this.close()
    })

    $(this.element).on('open-modal', (e) => {
      if(e.target !== this.element) {
        return
      }
      this.open()
    })

    $(this.element).on('close-modal', (e) => {
      if(e.target !== this.element) {
        return
      }
      this.close()
    })
  }

  close() {
    this.runCallbackQueue('modal.closing')
    $(this.element).removeClass('open')
    this.runCallbackQueue('modal.closed')
  }

  open() {
    this.registerEscapeListener()
    this.runCallbackQueue('modal.opening')
    $(this.element).addClass('open')
    this.renderContent()
    this.runCallbackQueue('modal.opened')
  }

  registerEscapeListener() {
    $('body').one('keydown', (e) => {
      if(e.keyCode === 27) { // esc key
        this.close()
      }
    })
  }

  renderContent() {
    $('.c-datatable', this.element).each((i, el) => {
      $(el).data('datatable').table.redraw(true)
    })
    $('.c-tabs', this.element).each((i, el) => {
      $(el).data('tabs').openByIndex(0)
    })
  }
}

export { Modal }