import * as _collection from 'lodash/collection'
import * as lang from 'lodash/lang'

let objects = Symbol('objects')
let history = Symbol('history')
let future = Symbol('future')

class ManagesObjects
{

  [objects] = []
  [history] = this
  [future] = this

  constructor(items) {
    this[objects] = items

    for(let method in _collection) {

      if(!_collection.hasOwnProperty(method)) {
        continue
      }

      if(typeof _collection[method] !== 'function') {
        continue
      }

      if(this[method]) {
        continue
      }

      Object.defineProperty(this, method, {
        writable: false,
        configurable: false,
        enumerable: true,
        value: (...args) => {
          let result = _collection[method](this[objects], ...args)
          if(result instanceof Array) {
            this[history] = lang.clone(this)
            this[objects] = result
            this[history][future] = lang.clone(this)
            return this
          }
          return result
        }
      })
    }
  }

  [Symbol.iterator]() {
    return this[objects].values()
  }

  undo() {
    return this[history]
  }

  redo() {
    return this[future]
  }
}



export { ManagesObjects }