import $ from 'jquery'
import moment from 'moment'
import { QuoteHeaderResource } from '../globals/Resources/QuoteHeaderResource'
import 'jquery-serializejson'
import { QuoteHeadersClient } from '../globals/Clients/QuoteHeadersClient'
import { QuotePhasesClient } from '../globals/Clients/QuotePhasesClient'
import { DenyMessagesClient } from '../globals/Clients/DenyMessagesClient'
import { DenyMessageResource} from '../globals/Resources/DenyMessageResource'
import { Route } from '../globals/Utilities/Route'
import { NotesClient } from '../globals/Clients/NotesClient'
import { NoteResource } from '../globals/Resources/NoteResource'
import { QuoteHeadersQuoteChargesClient } from '../globals/Clients/QuoteHeaderQuoteChargesClient'
import { QuoteChargeResource } from '../globals/Resources/QuoteChargesResource'
import { ShippingEntryClient } from '../globals/Clients/ShippingEntryClient'
import { ShippingEntryResource } from '../globals/Resources/ShippingEntryResource'
import { Str } from '../globals/Utilities/Str'
import { QuoteStatesClient } from '../globals/Clients/QuoteStatesClient'

class QuoteShowPage {

  static _name = 'quote-show'
  static _selector = '.p-quote-show'

  static headerFromFormData(data) {
    let quoteHeader = QuoteHeaderResource.make(data)
    quoteHeader.quote_phase_id = data.phase.id
    quoteHeader.sales_agent_id = data.sales_agent.id
    return quoteHeader
  }

  constructor(element) {
    this.element = element
    this.quoteHeaderId = $(this.element).data('quote-header-id')
    this.userId = $(this.element).data('user-id')
    this.headersClient = new QuoteHeadersClient()
    this.phasesClient = new QuotePhasesClient()
    this.chargesClient = new QuoteHeadersQuoteChargesClient(this.quoteHeaderId)
    this.chargesTable = $('#charges-datatable', this.element)
    this.notesTable = $('#notes-datatable', this.element)
    this.personalNotesTable = $('#personal-notes-datatable', this.element)
    this.denyMessagesClient = new DenyMessagesClient(this.quoteHeaderId)
    this.notesClient = new NotesClient(this.quoteHeaderId)
    this.shippingEntryClient = new ShippingEntryClient(this.quoteHeaderId)
    this.statesClient = new QuoteStatesClient()
    this.noteAlert = $('#note-alert')
    this.userModal = $('#user-modal')
    this.userTable = $('#user-datatable')
  }

  _redirectToIndex() {
    Route.redirectTo('/quotes/headers')
  }

  boot() {
    this.bindEvents()

    let types = ['user']

    let handler = (type) => {
      let modal = this[type + 'Modal']
      let table = this[type + 'Table']
      table.on('row-selected', (e, datatable) => {
        this.updateSalesAgent(datatable.table.getSelectedData()[0])
        this.fillFields(datatable.table.getSelectedData()[0])
        this.closeModal(modal)
      })
    }

    types.forEach(type => handler(type))

  }

  updateSalesAgent(data) {
    this.headersClient.update(this.quoteHeaderId, {'sales_agent_id': data.id })
  }

  closeModal(modal) {
    modal.removeClass('open').addClass('closed')
  }

  fillFields(data) {
    let contact_type = 'sales_agent'
    let name = data.name
    let phone = data.phone
    let email = data.email
    let number = data.number

    $("#" + contact_type + "_number").val(number).trigger('change')
    $("#" + contact_type + "_name").val(name).trigger('change')
    $("#" + contact_type + "_email").val(email).trigger('change')
    $("#" + contact_type + "_phone").val(phone).trigger('change')
  }

  bindEvents() {
    $('[data-target="#add-note"]').parent().data('button').disable(new Promise((resolve, reject) => {
      $('#add-note').on('change', function () {
        if($(this).val() !== "") {
          resolve()
        }
      })
    }))

    $('[data-target="#add-personal-note"]').parent().data('button').disable(new Promise((resolve, reject) => {
      $('#add-personal-note').on('change', function () {
        if($(this).val() !== "") {
          resolve()
        }
      })
    }))

    this.notesTable.data('datatable').registerCallback('cellEdited', (cell) => {
      this.updateNotes(cell)
    }, 101)

    this.chargesTable.on('edited-row', (e, data) => {
      this.updateQuoteHeaderCharges(data)
    })

    this.chargesTable.on('row.delete', (e, data) => {
      this.deleteQuoteHeaderCharge(data)
    })

    this.chargesTable.on('row.append.after', (e, data) => {
      this.storeNewCharge(data)
    })

    $(this.element).on('increment-phase', e => {
      e.preventDefault()
      this.incrementQuotePhase()
    })

    $(this.element).on('deny-quote', e => {
      this.resetPhase()
    })

    $(this.element).on('acknowledge-message', (e, data) => {
      this.disableMessage(data)
    })

    $(this.element).on('add-note', (e, data) => {
      this.saveNote(data)
    })

    $(this.element).on('add-personal-note', (e, data) => {
      this.savePersonalNote(data)
    })

    $(this.element).on('decrement-phase', e => {
      e.preventDefault()
      this.decrementQuotePhase()
    })

    $(this.element).on('save-shipping-address', (e, data) => {
      this.saveShippingAddress(data)
    })

    $(this.element).on('save-billing', (e, data) => {
      this.saveBilling(data)
    })


    $(this.element).on('save-time', (e, data) => {
      this.saveTime(data)
    })

    this.chargesTable.data('datatable').registerFormatter({field: 'total'}, (cell, formatterParams, onRendered) => {
      let data = cell.getRow().getData()

      return this.totalFormatter(data)
    })

    this.chargesTable.data('datatable').registerFormatter({field: 'total'}, (cell, formatterParams, onRendered, result) => {
      return '$' + Number(result).toFixed(2);
    }, 30)

    $(this.element).on('cancel-quote', e => {
      this.cancelQuote()
    })

    $(this.element).on('send-to-macola', (e, data) => {
      data.button.component.loading(
        this.sendToMacola()
      )
    })

    this.notesTable.data('datatable').registerFormatter({field: 'category'}, (cell, formatterParams, onRendered) => {
      let category = cell.getRow().getData().category
      let row = cell.getRow().getElement()

      if(category == 'invalid') {
        row.style.backgroundColor = '#CDCDCD'
      }
      else {
        row.style.backgroundColor = '#ffffff';
      }
      return category
    })

    this.noteAlert.hide()
  }

  updateNotes(data) {
    let row = data.getRow()
    let rowData = row.getData()

    this.notesClient.update(rowData.id, rowData)
      .then(response => {
      })
      .catch(data => {
        this.displayNoteUpdateDenial(data)
        this.restoreNote(data, row)
      })
  }

  displayNoteUpdateDenial(data) {
    this.noteAlert.find('p').remove()
    this.noteAlert.append('<p>' + data.data.message + '</p>')
    this.noteAlert.show()
  }

  restoreNote(data, row) {
    row.update({'category': data.data.category})
  }

  storeNewCharge(data) {
    let row = data[0]
    let rowData = row.getData()
    delete rowData.id

    let charge = QuoteChargeResource.make(rowData)

    this.chargesTable.data('datatable').disable()
    this.chargesClient.store(rowData)
      .then(response => {
        data[0].update(response.toArray().data)
        this.chargesTable.data('datatable').enable()
      })
  }

  updateQuoteHeaderCharges(data) {
    let d = data.row.getData()
    d.total = (d.gross_cost * d.quantity).toFixed(2)

    if(Number.isNaN(d.total)) {
      d.total = 0
    }

    data.datatable.table.updateRow(data.row, d)

    let quoteChargeResource = new QuoteChargeResource(d)

    let charge_words = Str.words(quoteChargeResource.title)
    charge_words.forEach(function(word, index, array) {
      array[index] = Str.capitalize(word)
    })
    let name = charge_words.join(' ')
    
    quoteChargeResource.name = name

    this.chargesClient.update(d.id, quoteChargeResource)
  }

  deleteQuoteHeaderCharge(data) {
    data.datatable.disable()
    this.chargesClient.destroy(data.data.id)
      .then(r => {
        data.datatable.enable()
      })
  }

  incrementQuotePhase() {
    let quoteHeaderId = $(this.element).data('quote-header-id')
    let phases = this.phasesClient.index()
    let quote = this.headersClient.get(quoteHeaderId)

    Promise.all([phases, quote])
      .then( ([phases, quote]) => {
        let currentPhase = phases
          .find(item => {
            return item.id === quote.phase.id
          })
        let nextPhase = phases
          .find((item) => {
            return item.position > currentPhase.position
          })

        if(!nextPhase) {
          return
        }

        quote.quote_phase_id = nextPhase.id
        quote.phase = nextPhase

        return this.headersClient.update(this.quoteHeaderId, quote, this.userId)
      } )
      .then(this._redirectToIndex)
  }

  decrementQuotePhase() {
    let quoteHeaderId = $(this.element).data('quote-header-id')
    let phases = this.phasesClient.index()
    let quote = this.headersClient.get(quoteHeaderId)

    Promise.all([phases, quote])
      .then( ([phases, quote]) => {
        let currentPhase = phases
          .find(item => {
            return item.id === quote.phase.id
          })
        let prevPhase = phases
          .find((item) => {
            return item.position === currentPhase.position - 1
          })

        if(!prevPhase) {
          return
        }

        quote.quote_phase_id = prevPhase.id
        quote.phase = prevPhase

        return this.headersClient.update(this.quoteHeaderId, quote, this.userId)
      } )
      .then(this._redirectToIndex)
  }

  resetPhase() {
    let phases = this.phasesClient.index()
    let quote = this.headersClient.get(this.quoteHeaderId)

    Promise.all([phases, quote])
      .then( ([phases, quote]) => {
        let firstPhase = phases.sortBy(['position'])[0]
        let midPhase = phases.sortBy(['position'])[8]

        if(quote.quote_phase_id >= midPhase.id) {
          quote.quote_phase_id = midPhase.id
          quote.phase = midPhase
        }
        else {
          quote.quote_phase_id = firstPhase.id
          quote.phase = firstPhase
        }
        return quote
      } )
      .then( quote => {
        this.headersClient.update(quote.id, quote)
      })
      .then(this._redirectToIndex)
  }

  disableMessage(data) {
    let message_id = data.target.denyMessageId;
    let alert = data.button.target;
    let resource = new DenyMessageResource({
        'id': message_id,
        'is_active': 0
    });
    this.denyMessagesClient.update(message_id, resource)
      .then(function(response) {
      /*if(response.status === 200) {*/
          $('#deny-message-' + message_id).hide()
      /*}*/
        } )
  }

  saveNote(data) {
      let note = $(data.button.target).val()

      let noteObj = {
        'note': note,
        'category': 'internal'
      }
      noteObj = new NoteResource(noteObj)

      data.button.component.loading(
      this.notesClient.store(noteObj, this.userId, this.quoteHeaderId)
        .then( (response) => {
          let table = this.notesTable.data('datatable').table
            response.data.created_at = moment(response.data.created_at).format('YYYY-MM-DD hh:mm:ss')
            table.addData(response.toArray().data)
        })
      )
  }

  savePersonalNote(data) {
    let note = $(data.button.target).val()

    let noteObj = {
      'note': note,
      'category': 'personal'
    }
    noteObj = new NoteResource(noteObj)

    data.button.component.loading(
      this.notesClient.store(noteObj, this.userId, this.quoteHeaderId)
        .then( (response) => {
          let table = this.personalNotesTable.data('datatable').table
          response.data.created_at = moment(response.data.created_at).format('YYYY-MM-DD hh:mm:ss')
          table.addData(response.toArray().data)
        })
    )
  }

  saveShippingAddress(data) {
    let shippingAddr = $('#shipping-form').serializeJSON()
    let id = shippingAddr.id
    delete shippingAddr.id

    shippingAddr.type = 'single'
    shippingAddr.quote_header_id = this.quoteHeaderId

    shippingAddr = new ShippingEntryResource(shippingAddr)

    data.button.component.loading(
      this.shippingEntryClient.update(id, shippingAddr)
    )
  }

  saveBilling(data) {
    let billing = $('#billing-form').serializeJSON()
    let id = billing.id

    billing = new QuoteHeaderResource(billing)

    data.button.component.loading(
      this.headersClient.update(id, billing)
    )
  }

  saveTime(data) {
    let time = $('#time-form').serializeJSON()
    let id = time.id

    time = new QuoteHeaderResource(time)

    data.button.component.loading(
      this.headersClient.update(id, time)
    )
  }

  totalFormatter(rowData) {
    return rowData.gross_cost * rowData.quantity
  }

  cancelQuote() {
    let states = this.statesClient.index()
    let quote = this.headersClient.get(this.quoteHeaderId)

    Promise.all([states, quote])
      .then( ([states, quote]) => {
        let cancelledState = states.sortBy(['name'])[0]
        quote.quote_status_id = cancelledState.id
        quote.state = cancelledState
        return quote
      } )
      .then( quote => {
        this.headersClient.update(quote.id, quote)
      })
      .then(this._redirectToIndex)
  }

  sendToMacola() {
    this.headersClient.sendToMacola(this.quoteHeaderId)
  }

}

export { QuoteShowPage }