import objectToFormData from 'object-to-formdata'
import formDataToObject from 'formdata-to-object'


global.formData = global.formData || global.FormData || {}

class FormObject {

  static objectToFormData(obj) {
    return objectToFormData(obj, {
      indices: true,
      nulls: false
    })
  }

  static formDataToObject(form) {
    return formDataToObject(form)
  }
}


export { FormObject }