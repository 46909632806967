import { AbstractResource } from './AbstractResource'

class QuotePhaseResource extends AbstractResource {

  static keys = {
    "id": Number,
    "name": String,
    "description": String,
    "position": Number
  }
}

export { QuotePhaseResource }