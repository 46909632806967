import 'jquery-serializejson'
import { AbstractClient } from './AbstractClient'
import { QuotePhasesCollectionResource } from '../Resources/QuotePhasesCollectionResource'

/**
 * @method get(int id)
 * @method index()
 * @method store(QuotePhaseResource data)
 * @method update(int id, QuotePhaseResource data)
 * @method delete(int id)
 */
class QuotePhasesClient extends AbstractClient {

  collectionType = QuotePhasesCollectionResource

  get uri() {
    return '/api/quotes/phases'
  }
}

export { QuotePhasesClient }