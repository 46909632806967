import 'jquery-serializejson'
import { AbstractClient } from './AbstractClient'
import { ShippingEntriesCollectionResource } from '../Resources/ShippingEntriesCollectionResource'

/**
 * @method get(int id)
 * @method index()
 * @method store(QuoteHeaderResource data)
 * @method update(int id, QuoteHeaderResource data)
 * @method delete(int id)
 */
class ShippingEntryClient extends AbstractClient {

  collectionType = ShippingEntriesCollectionResource

  get uri() {
    return '/api/quotes/headers/' + this.quote_header_id + '/shipping'
  }

  constructor(quote_header_id) {
    super()
    this.quote_header_id = quote_header_id
  }
}

export { ShippingEntryClient }