import $ from 'jquery'

class Field
{

  static _name = 'field'
  static _selector = '.field'

  constructor (element) {
    this.element = element
    this.fields = $('input, textarea, select', this.element).get()
    this.form = $(element).closest('form')
  }

  static _setValidityClasses (field) {
    if (field.type === 'checkbox') {
      return
    }

    field.checkValidity()
      ? $(field).addClass('valid').removeClass('invalid')
      : $(field).addClass('invalid').removeClass('valid')

    field.value.trim() === ''
      ? $(field).addClass('empty')
      : $(field).removeClass('empty')

    ;(field.value.trim() === '' && field.required)
      ? $(field).removeClass('valid').addClass('invalid')
      : $(field).removeClass('invalid').addClass('valid')
  }

  boot () {
    this.fields.forEach((field) => {
      Field._setValidityClasses(field)
    })
    this.bindEvents()
  }

  bindEvents () {
    let t = self
    this.fields.forEach((el) => {
      $(el).on('keyup change', function () {
        Field._setValidityClasses(this)
      })
    })
  }
}

export { Field }