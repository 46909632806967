/**
 * Utility for checking a value against classes
 * @class TypeValidator
 */
class TypeValidator {

  #val = null
  #primitives = {
    'string': String,
    'number': Number,
    'boolean': Boolean,
    'object': Object,
    'symbol': Symbol
  }

  _typeOf(obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
  }

  _nameOf(value) {
    if(this._typeOf(value) === 'function') {
      return value.name
    }
    return value.constructor.name
  }

  constructor(value) {
    this.#val = value
  }

  isPrimitive() {
    return this.#primitives.hasOwnProperty(
      this._nameOf(this.#val)
    )
  }

  isNotPrimitive() {
    return !this.isPrimitive()
  }

  /**
   * Is null, undefined, or NaN
   * @returns {boolean}
   */
  isDirty() {
    return (
      this.#val === undefined
      || this.#val === null
      || this.isInstanceOf(Number) && Number.isNaN(this.#val)
    )
  }

  isClean() {
    return !this.isDirty()
  }

  isEmpty() {
    return (this.isA(String) && this.#val == '')
      || (this.isAn(Array) && this.#val.length === 0)
      || (this.isAn(Object) && Object.keys(this.#val).length === 0)
  }

  isNotEmpty() {
    return !this.isEmpty()
  }

  /**
   * Is an instance of the given class or one of its child classes
   * @param type
   * @returns {boolean}
   */
  isInstanceOf(type) {
    return this.#val instanceof type
  }

  /**
   * Is not an instance of the given class or its child classes
   * @param type
   * @returns {boolean}
   */
  isNotInstanceOf(type) {
    return !this.isInstanceOf(type)
  }

  /**
   * Is an instance of the given class. Does not consider child classes
   * @param type
   * @returns {boolean}
   */
  isA(type) {
    let typeInst = new type()
    let sameName = this._nameOf(typeInst) === this.#val.constructor.name

    return (sameName)
  }

  /**
   * Alias of isA
   * @param type
   * @returns {boolean}
   */
  isAn(type) {
    return this.isA(type)
  }

  /**
   * Is not an instance of the given class. Does not consider child classes
   * @param type
   * @returns {boolean}
   */
  isNotA(type) {
    return !this.isA(type)
  }

  isNotAn(type) {
    return this.isNotA(type)
  }

  /**
   * Is the given class or extends from it
   * @param type
   * @returns {boolean}
   */
  is(type) {
    return this.#val.prototype instanceof type
  }

  /**
   * Is not the given class
   * @param type
   * @returns {boolean}
   */
  isNot(type) {
    return !this.is(type)
  }

}

export { TypeValidator }