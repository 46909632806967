/**
 * Note: Order matters here.
 */


import $ from 'jquery'
import moment from 'moment'

import { Templates } from './templates/_base'
import { Components } from './components/_base'
import { Compositions } from './compositions/_base'
import { Pages } from './pages/_base'

window.$ = $
window.moment = moment

let components = Components.register()
let templates = Templates.register()
let compositions = Compositions.register()
let pages = Pages.register()

$(function () {
  components.boot()
  templates.boot()
  compositions.boot()
  pages.boot()

  $('body').trigger('js.loaded')
})



