import $ from 'jquery'

class AreaSidebar {
    constructor(element) {
        this.element = element

    }

    boot() {
        $(this.element).removeClass('open closed')
        $(this.element).addClass('closed')
        this.bindEvents()
    }

    bindEvents() {
            $(this.element).find('.button-expand').on('click', () => {this.toggle()})
            $(this.element).find('.button-contract').on('click', () => {this.toggle()})
        }

    toggle() {
        $(this.element).toggleClass('open closed')
    }
}

AreaSidebar._name = 'area-sidebar'
AreaSidebar._selector = '.area-sidebar'

export {AreaSidebar}