import { Str } from './Str'

class Route {

  static getAsTag(url) {
    let a = document.createElement('a')
    a.href = url

    return a
  }

  static redirectTo(url) {
    let a = this.getAsTag(url)
    window.location = a.href
  }

  static objectFromUrlQuery(url) {
    let a = this.getAsTag(url)
    let params = new URLSearchParams(a.search)

    let result = {}
    for(let entry of params.entries()) { // each 'entry' is a [key, value] tupple
      const [key, value] = entry;
      result[key] = value;
    }
    return result;
  }

  static attachQueryToUrl(url, queryParams = {}) {

    let tag = Route.getAsTag(url)
    let originalQuery = Route.objectFromUrlQuery(url)

    let queryObj = $.extend({}, originalQuery, queryParams)



    let queryString = Object.keys(queryObj)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]))
      .join('&');


    url = Str(tag.pathname)

    return [url.valueOf(), queryString].join('?')
  }
}

export { Route }