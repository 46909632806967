import { mix } from '../globals/Utilities/MixinBuilder'
import { HasCallbackQueues } from '../globals/Utilities/HasCallbackQueue'

class Tabs extends mix().with(HasCallbackQueues) {
  static _name = 'tabs'
  static _selector = '.c-tabs'

  constructor(element, ...args) {
    super(...args)
    this.element = element
  }

  boot() {
    this.registerTabs()
    this.bindEvents()

    if($(this.element).is(':visible')) {
      this.openByIndex(0)
    }
  }

  registerTabs() {
    this.tabs = $(this.element).children('.tabs').children('.tab')
    this.titles = $(this.element).children('.titles').children('.tab-title')

    this.registerCallback('opening', (data) => {
      $(this.element).trigger('tab.opening')
    }, Number.NEGATIVE_INFINITY)

    this.registerCallback('opened', (data) => {
      $(this.element).trigger('tab.opened')
    }, Number.NEGATIVE_INFINITY)
  }

  bindEvents() {
    $(this.element).on('open-tab', (e, data) => {
      e.stopPropagation()

      let tab = this.tabs.filter(data.button.tabTarget).first(),
        title = this.titles.filter('[data-tab-id="'+data.button.tabTarget+'"]').first(),
        index = this.tabs.index(tab),
        previous = this.tabs.filter('.active').first()

      data = {
        tab,
        title,
        index,
        previous
      }

      this.runCallbackQueue('opening', data)

      this.open(data)

      this.runCallbackQueue('opened', data)
    })
  }

  openByIndex(index) {
    this.open({
      tab: $(this.tabs.get(index)),
      title: $(this.titles.get(index))
    })
  }

  open(data) {
    let tab = data.tab
    let title = data.title

    let active = this.tabs.filter('.active')

    this.tabs.removeClass('active')
    this.titles.removeClass('active')
    this.titles.find('.c-button').removeClass('successful')

    this.tabs.hide()

    tab.addClass('active').show()
    title.addClass('active')
    title.find('.c-button').addClass('successful')

    this.renderSubComponents()
  }

  renderSubComponents() {
    $(this.element).find('.c-datatable').each(function() {
      $(this).data('datatable').table.redraw(true)
    })
  }
}

export { Tabs }