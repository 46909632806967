import $ from 'jquery'
import { Components } from './_base'

class Template
{

  static _selector = '.c-template'
  static _name = 'template'

  constructor (element) {
    this.element = element
    this.template = $('template', this.element)
    this.container = $($(this.element).data('container'), this.element).first()
  }

  boot () {
    this.bindEvents()

    $('body').on('js.pages.loaded', () => {
      this.loadInstances()
    })
  }

  bindEvents () {
    $(this.element).on('delete-item', '.template-instance',(e, data) => {
      e.stopPropagation()
      console.log(data)
      this.deleteItem(data)
    })
    $(this.element).on('add-item', (e, data) => {
      e.stopPropagation()
      this.addItem(data)
    })
  }

  loadInstances() {
    let startWith = $(this.element).data('start-with')

    if(! startWith) {
      return
    }

    let inc = 0;
    while(inc !== startWith) {
      this.addItem({})
      inc++
    }
  }

  addItem(data) {
    let instanceIdInt = this.template.data('increment')
    let clone = $(this.template.html()).clone()

    this.template.data('increment', instanceIdInt + 1)

    let deleteButton = clone
      .find('.c-button.delete-item')

    clone = $(clone)
      .data('instance', instanceIdInt)
      .appendTo(this.container)
      .hide()
      .toggle(200)

    deleteButton
      .data('increment', this.template.data('increment') - 1)
      .attr('increment', this.template.data('increment') - 1)


    $(clone)
      .find('.component')
      .each(function() {
        Components.forceBoot(this)
      })

    /**
     * @deprecated Listen for the cloned-instance event on the template element instead
     */
    $('body').trigger('cloned-instance', {
      instance: $(clone),
      template: this
    })

    $(this.element).trigger('cloned-instance', {
      instance: $(clone),
      template: this
    })

  }

  deleteItem(data) {
    let targetInstance = $(data.target.element)

    targetInstance.animate({
        opacity: 0,
        width: 0,
        height: 0
      }, 200)
      .queue(function() {
        $(this).remove().dequeue();
      })

    /**
     * @deprecated Listen for the deleted-instance even on the template element instead
     */
    $('body').trigger('deleted-instance', {
      instance: targetInstance,
      template: this
    })

    $(this.element).trigger('deleted-instance', {
      instance: targetInstance,
      template: this
    })
  }

}

export { Template }

