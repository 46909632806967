import $ from 'jquery'
import axios from 'axios'
import Tabulator from 'tabulator-tables'

class QuoteCreatePage {

  static _name = 'quote-create'
  static _selector = '.p-quote-create'

  constructor(element) {
    this.element = element
    this.customerTable = $('#customer-datatable')
    this.supplierTable = $('#supplier-datatable')
    this.decoratorTable = $('#decorator-datatable')
    this.customerModal = $('#customer-modal')
    this.supplierModal = $('#supplier-modal')
    this.decoratorModal = $('#decorator-modal')

    let po = $(QuoteCreatePage._selector).find('input[name="macola_po_number"]').parent()
    let reorder = $(QuoteCreatePage._selector).find('input[name="macola_reorder_number"]').parent()
    po.hide()
    reorder.hide()

    $(this.element).one('submit-quote-header', (e, data) => {
      if(data.target.response.status === 201) {
        let itemsUrl = '/quotes/headers/' + data.target.response.data.data.id + '/items/create'
        window.location = itemsUrl
      }
    })

    $(this.element).on('change', "[name='is_reorder']" , function(e) {
      let is_reorder = $(this).prop("checked")

      if (is_reorder === true) {
        po.show()
        reorder.show()
      }
      else {
        po.hide()
        reorder.hide()
      }

    })
    this.customerModal.find('[name="sales_agent_number"]').on('keydown', (e) => {
      if(e.which === 13) {
        this.customerModal.find('#load-customers').children('button').trigger('click')
      }
    })
    this.customerModal.on('update-customer-sales-agent-number', (e, data) => {
      let input = this.customerModal.find('[name="sales_agent_number"]')

      if(!input.val()) {
        return
      }

      let dt = this.customerTable.data('datatable')
      dt.url = '/api/macola/customers?sales_agent_number=' + input.val()

      dt.reload()

    })

    $(".readonly").keydown(function(e){
      e.preventDefault();
    });
  }

  boot() {
    let types = [
      'customer', 'supplier', 'decorator'
    ]

    let handler = (type) => {
      let modal = this[type + 'Modal']
      let table = this[type + 'Table']
      table.on('row-selected', (e, datatable) => {
        this.fillFields(datatable.table.getSelectedData()[0], type)
        this.closeModal(modal)
      })
    }

    types.forEach(type => handler(type))

  }

  bindEvents(wrappers) {
    let t = this

    for (let event in wrappers) {
      modal.on('change', 'input', function() {
        t.filterDataTable(table.data('tabulator'), $(this).val())
      })
    }
  }

  openModal(modal) {
    modal.removeClass('closed').addClass('open')
  }

  closeModal(modal) {
    modal.removeClass('open').addClass('closed')
  }

  fillFields(data, target) {
    let contact_type = target
    let name = data.name
    let phone = data.phone
    let email = data.email
    let number = data.number

    $("#" + contact_type + "_number").val(number).trigger('change')
    $("#" + contact_type + "_name").val(name).trigger('change')
    $("#" + contact_type + "_email").val(email).trigger('change')
    $("#" + contact_type + "_phone").val(phone).trigger('change')
  }

}

export { QuoteCreatePage }