class AppTemplate
{

  static _name = 'app'
  static _selector = '.app-template'

  constructor (element) {
    this.element = element
  }

  boot () {

  }
}

export { AppTemplate }