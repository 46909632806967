import 'jquery-serializejson'
import { AbstractClient } from './AbstractClient'
import { QuoteStatesCollectionResource } from '../Resources/QuoteStatesCollectionResource'

/**
 * @method get(int id)
 * @method index()
 * @method store(QuotePhaseResource data)
 * @method update(int id, QuotePhaseResource data)
 * @method delete(int id)
 */
class QuoteStatesClient extends AbstractClient {

  collectionType = QuoteStatesCollectionResource

  get uri() {
    return '/api/quotes/states'
  }
}

export { QuoteStatesClient }