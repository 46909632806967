import $ from 'jquery'

import { AccordionMenu } from './AccordionMenu.js'
import { Alert } from './Alert.js'
import { Card } from './Card.js'
import { CardGrid } from './CardGrid.js'
import { DropdownMenu } from './DropdownMenu.js'
import { Field } from './Field.js'
import { Modal } from './Modal.js'
import { Page } from './Page.js'
import { ProgressBar } from './ProgressBar.js'
import { ProgressStep } from './ProgressStep.js'
import { Template } from './Template.js'
import { TitleBar } from './TitleBar.js'
import { AreaSidebar } from './AreaSidebar.js'
import { Button } from './Button.js'
import { DataTable } from './DataTable'
import { DragDropUploader } from './DragDropUploader'
import { Tabs } from './Tabs'
import { MediaSelector } from './MediaSelector'

class Components {

  static forceBoot(el) {
    this.componentList().forEach((componentCls) => {
      if ($(el).is(componentCls._selector)) {
        this.bootInstance(el, componentCls)
      }
    })
  }

  constructor (components) {
    this.components = components
  }

  static componentList() {
    return [
      AccordionMenu,
      Alert,
      AreaSidebar,
      Card,
      CardGrid,
      DropdownMenu,
      Field,
      Modal,
      Page,
      ProgressBar,
      ProgressStep,
      Template,
      TitleBar,
      Button,
      DataTable,
      DragDropUploader,
      Tabs,
      MediaSelector
    ]
  }

  static register () {
    return new this(Components.componentList())
  }

  boot () {
    $(() => {
      this.components.forEach((c) => {
        let elms = document.querySelectorAll(c._selector)

        let elmsArr = [].slice.call(elms)

        elmsArr.forEach((el) => {
          if(el.classList.contains('composition')) {
            return
          }

          this.constructor.bootInstance(el, c)
        })
      })

      $('body').trigger('js.components.loaded')
    })
  }

  static bootInstance(el, c) {
    let inst = new c(el)

    $(el).data(c._name, inst)
    inst.boot()
    $(el).trigger(c._name + '.booted', inst)
  }
}

export { Components }