class CardGrid
{

  static _name = 'card-grid'
  static _selector = '.c-card-grid'

  constructor (element) {
    this.element = element
  }

  boot () {

  }
}

export { CardGrid }