import 'jquery-serializejson'
import { QuoteItemsCollectionResource } from '../Resources/QuoteItemsCollectionResource'
import { AbstractClient } from './AbstractClient'
/**
 * @method get(int id)
 * @method index()
 * @method store(QuoteItemResource data)
 * @method update(int id, QuoteItemResource data)
 * @method delete(int id)
 */
class QuoteItemsClient extends AbstractClient {

  collectionType = QuoteItemsCollectionResource

  get uri() {
    return '/api/quotes/headers/' + this.header_id + '/items'
  }

  constructor(header_id) {
    super()
    this.header_id = header_id
  }

  store(data) {
    data = super.constructor.parseData(data)

    if(data instanceof FormData) {
      data.append('quote_header_id', this.header_id)
    } else {
      data.data.quote_header_id = this.header_id
    }

    return super.store(data)
  }
}

export { QuoteItemsClient }