import { AbstractResource } from './AbstractResource'
import { QuoteItemResource } from './QuoteItemResource'
import { FileUploadResource } from './FileUploadResource'

class QuoteItemArtworkResource extends AbstractResource {
  static keys = {
    "id": Number,
    "colors": String,
    "implementation": String,
    "product_location": String,
    "file_upload": FileUploadResource,
    "quote_item": QuoteItemResource,
    "size": String,
    "width": Number,
    "height": Number,
    "filename": String,
    "eps_file": FileUploadResource
  }
}

export { QuoteItemArtworkResource }