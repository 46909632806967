import $ from 'jquery'
import 'jquery-serializejson'
import { QuoteItemResource as QuoteItem } from '../globals/Resources/QuoteItemResource'
import { QuoteItemsClient } from '../globals/Clients/QuoteItemsClient'
import { FormObject } from '../globals/Utilities/FormObject'
import { QuoteItemArtworkResource } from '../globals/Resources/QuoteItemArtworkResource'
import { QuoteItemArtworksClient } from '../globals/Clients/QuoteItemArtworkClient'

class QuoteItemsCreatePage {

  static _name = 'quote-items-create'
  static _selector = '.p-quote-items-create'

  static itemsFromFormData(data) {
    let items = []

    for(let size in data.sizes) {
      if(!data.sizes.hasOwnProperty(size)) {
        continue
      }

      let item = new QuoteItem(data)

      size = data.sizes[size]

      item.unit_material_charge = size.material_cost
      item.commission_percent = size.commission
      item.size = size.size
      item.unit_material_charge = size.material_cost
      item.quantity = size.quantity
      item.commission_percent = size.commission

      items.push(item)
    }
    return items
  }

  static itemFromResponseData(data) {
    let item = new QuoteItem(data)
    return item
  }

  constructor(element) {
    this.element = element
    this.items = []
    this.form = $('#create-items-form', this.element)
    this.sizeTemplate = this.element.querySelector('#size-template')
    this.createMoreButton = $('#add-another-size', this.form)
    this.addItemsButton = $('#add-to-order', this.form)
    this.client = new QuoteItemsClient(this.form.serializeJSON().quote_header_id)
    this.artworkClient = new QuoteItemArtworksClient()
    this.alert = $('#quote-items-alert').data('alert')
    this.alert.dismiss()
    this.datatable = $('#items-datatable')
    this.table = this.datatable.data('line-item-datatable').table
    this.logoCloner = $('.logo-template')
  }

  boot() {
    this.bindEvents()
    this.registerArtworkSelection()
    this.registerArtworkUpload()
  }

  registerArtworkSelection() {
    this.logoCloner.on('cloned-instance', (e, data) => {
      let instance = data.instance
      let input = instance.find('[name="artwork[][file_upload_id]"]')
      let table = instance.find('.c-datatable')
      let modal = table.closest('.c-modal')
      table.data('datatable').registerCallback('rowSelected', (row) => {
        let id = row.getData().id
        input.val(id)
        modal.data('modal').close()
      })
    })
  }

  registerArtworkUpload() {
    this.logoCloner.on('cloned-instance', (e, data) => {
      let table = data.instance.find('.c-datatable').data('datatable').table
      let dragDrop = data.instance.find('.c-drag-drop-uploader')
      dragDrop
        .data('dragdropuploader')
        .registerCallback('uploaded', (result, file) => {
        })
    })
  }

  addFileToTable(table, file) {
    table.addRow(file)
  }

  bindEvents() {

    $(this.element).on('add-size', (e) => {
      e.preventDefault()
      this.addSize()
    })

    $(this.element).on('submit-items', (e, data) => {

      if (!this.form.get(0).checkValidity()) {
        this.form.get(0).reportValidity()
        return
      }

      let buttonInst = $(data.button.element).data('button')
      let previewButtonInst = $('.footer-inner [data-action^="/quotes/headers"][data-target="window"]').first().closest('.c-button').data('button')

      data.button.element.addClass('disabled')

      previewButtonInst.loading(
        Promise.resolve(
          buttonInst.loading(this.addItems())
            .then(() => Promise.resolve(data.button.element.removeClass('disabled')))
      ))
    })

    $(this.element).on('cloned-instance', (e, data) => {
      e.preventDefault()

      let fileText = data.instance
        .find('.image-file-text')

      let file = data.instance
        .find('.image-file')

      if(fileText.length) {
        file.attr('id', 'file-input-' + data.instance.data('increment'))
        fileText.on('focus', function() {
          fileText.trigger('blur')
          file.trigger('click')
        })
        file.on('change', function() {
          let v = file[0].files.length ? file[0].files[0].name : ''
          fileText.val(v)
        })
      }
    })

    $(this.element).on('change', "[name='artwork[][size]']" , function(e) {
      let dropdown_container = $(e.currentTarget).closest('.c-dropdown-field')
      let size = $(this).val()
      let width_input = dropdown_container.next().find('input[name="artwork[][width]"]')
      let height_input = dropdown_container.next().next().find('input[name="artwork[][height]"]')

      if (size === 'custom') {
        width_input.show()
        height_input.show()
      }
      else {
        width_input.hide()
        height_input.hide()
        width_input.val(0)
        height_input.val(0)
      }

    })
  }

  getFormData() {
    return this.form.serializeJSON()
  }

  addDataToTable(data) {
    this.table.addData(data)
  }

  addErrorsToAlert(errors) {
    let alert = $('#quote-items-errors')

    let errors_html = '';


    for (var property in errors) {
      if (errors.hasOwnProperty(property)) {
        errors_html += '<p><strong style="text-transform: capitalize">' + property + '</strong>: ' + errors[property] + '</p>'
      }
    }

    alert.html(errors_html)

    this.alert.display()
  }

  addItem(data) {
    return this.client
      .store(data)
      .catch(response => {
        if(response.success !== undefined) {
          this.addErrorsToAlert(response.data)
        }
        return response
      })
  }

  async addItems() {
    let itemsQueue = []
    let artworksQueue = []

    let serialized = this.form.serializeJSON()

    console.log(serialized)

    let items = QuoteItemsCreatePage.itemsFromFormData(serialized)

    items.forEach(item => {
      let formData = FormObject.objectToFormData(item.toArray().data)
      itemsQueue.push(this.addItem(formData))
    })

    let quoteItems = await Promise.all(itemsQueue)

    quoteItems.forEach(quoteItem => {
      serialized.artwork = serialized.artwork || []
      serialized.artwork.forEach(artwork => {
        let artworkResource = QuoteItemArtworkResource.make(artwork).toArray()
        artworkResource.data.file_upload_id = artwork.file_upload_id
        artworkResource.data.eps_file_id = artwork.eps_file_id
        artworkResource.data.quote_item_id = quoteItem.data.id
        let uniqResource = Object.assign({}, artworkResource.data)
        uniqResource = {data: uniqResource}
        artworksQueue.push(this.artworkClient.store(uniqResource))
      })
    })

    await Promise.all(artworksQueue)

    itemsQueue = quoteItems.map(quoteItem => {
      return this.client.show(quoteItem.data.id)
    })

    itemsQueue.forEach(async call => {
      let result = await call
      this.addDataToTable(result.data)
    })

    return Promise.all(itemsQueue)
  }
}

export { QuoteItemsCreatePage }