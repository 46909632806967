import { AbstractResource } from './AbstractResource'

class QuoteChargeResource extends AbstractResource {

  static keys = {
    'id': Number,
    'name': String,
    'title': String,
    'gross_cost': Number,
    'net_cost': Number,
    'quantity': Number,
    'chargeable': AbstractResource,
    'macola_item_number': String
  }

}

export { QuoteChargeResource }