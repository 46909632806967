import { AbstractResource } from './AbstractResource'
import { QuoteItemsCollectionResource } from './QuoteItemsCollectionResource'
import { QuotePhaseResource } from './QuotePhaseResource'
import { UserResource } from './UserResource'
import { QuoteChargeResource } from './QuoteChargesResource'
import { QuoteStateResource } from './QuoteStateResource'

class QuoteHeaderResource extends AbstractResource {

  static keys = {
    id: Number,
    customer_email: String,
    customer_name: String,
    customer_number: String,
    customer_phone: String,
    decorator_email: String,
    decorator_name: String,
    decorator_phone: String,
    notes: String,
    sales_agent_id: String,
    quote_phase_id: Number,
    supplier_email: String,
    supplier_name: String,
    supplier_number: String,
    supplier_phone: String,
    items: QuoteItemsCollectionResource,
    phase: QuotePhaseResource,
    sales_agent: UserResource,
    administrative_agent: UserResource,
    charges: QuoteChargeResource,
    state: QuoteStateResource,
    quote_status_id: Number,
    macola_po_number: Number,
    macola_reorder_number: Number,
    contact_name: String,
    contact_email: String,
    contact_phone: String,
    production_time: String,
    shipping_time: String,
    graphics_agent: UserResource,
    current_user: UserResource,
    commission_total: Number,
    freight_charge: Number
  }
}

export { QuoteHeaderResource }